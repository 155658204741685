import { MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import ApiService from "../../../Core/Service/ApiService";
import { getAvatarUrl, v_url } from "../../../Utils/utils";
import Moment from "react-moment";
import LoadingIndicator from "../../../Components/LoadingIndicator/LoadingIndicator";
import { useNavigate } from "react-router-dom";

const ClientAdvisorStatistics = React.memo(props => {
    const { aId } = props;
    const [adStatistics, setAdStatistics] = useState();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(()=>{
        {(async ()=>{
            try {
                setLoading(true);
                let response = await ApiService.get(`/client/advisor/${aId}/statistics`);
                setAdStatistics(response.data);
            } finally {
                setLoading(false);
            }
        })()}
    }, [aId]);

    const viewAdvisorDetail = () => {
        navigate(v_url(`/advisor/${adStatistics.advisor._id}`));
    }

    return (
        <MDBCard>
            <MDBCardBody>
                {adStatistics && (
                <div className="d-flex align-items-center">
                    <img src={getAvatarUrl(adStatistics.advisor.avatar)} 
                        className="round-avatar-80 cursor-pointer" 
                        style={{border: '3px solid gold'}}
                        onClick={viewAdvisorDetail}
                    />
                    <div className="ms-2">
                        <div className="text-purple fw-bold cursor-pointer" onClick={viewAdvisorDetail}>
                            {adStatistics.advisor.username}
                        </div>
                        <div>
                            <label>Total Chats: </label>
                            <span className="ms-2 fw-bold text-gray555">{adStatistics.chat_count}</span>
                        </div>
                        <div>
                            <label>Last Chat:</label>
                            <span className="ms-2 fw-bold text-gray555"><Moment format="YYYY-MM-DD">{adStatistics.last_chat.createdAt}</Moment></span>
                        </div>
                    </div>
                </div>
                )}
                {loading && <LoadingIndicator/>}
            </MDBCardBody>
        </MDBCard>
    )
});

export default ClientAdvisorStatistics;