import { MDBCard, MDBCardBody, MDBCardHeader, MDBContainer, MDBIcon, MDBInput } from "mdb-react-ui-kit";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ApiService from "../../../Core/Service/ApiService";
import LoadingIndicator from "../../../Components/LoadingIndicator/LoadingIndicator";
import SVG from 'react-inlinesvg';
import { getAvatarUrl, v_url } from "../../../Utils/utils";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Paginator from "../../../Components/Paginator/Paginator";

const MyAdvisors = React.memo((props) => {
    const [total, setTotal] = useState(0);
    const [advisors, setAdvisors] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [searchFormData, setSearchFormData] = useState({
        search: '',
        advisorType: 'all',
        perPage: 10,
        pageNum: 1
    })
    const advisorTypeOpts = [
        {value: 'all', label: 'All'},
        {value: 'favorite', label: 'Favorite'}
    ]
    const [keyword, setKeyword] = useState('');

    const gotoAdvisorDetail = (advisor) => {
        navigate(v_url(`/client/advisor/detail/${advisor._id}`));
    }

    useEffect(()=>{
        getMyAdvisors();
    }, [searchFormData]);

    const getMyAdvisors = async () => {
        try {
            setLoading(true);

            // To get lastChats by advisor
            let response = await ApiService.get('/client/chat/myadvisors', searchFormData);
            setTotal(response.data.total);
            const { ad_lastChats } = response.data;
            
            if(ad_lastChats?.length > 0) {
                // To get total chatoff_message by advisors.
                const aIds = ad_lastChats.map(lc => lc.aId._id);
                response = await ApiService.post('/chatoff_message/totalcount', {partnerIds: aIds});
                const { messageCounts } = response.data;
                setAdvisors(ad_lastChats.map(lc => {
                    const mc = messageCounts.find(mc => mc._id == lc.aId._id);
                    return {
                        ...lc.aId,
                        lastChat: lc.lastChat,
                        chatOffMessageCount: mc?.total ?? 0
                    }
                }));
            } else {
                setAdvisors([]);
            }
        } finally {
            setLoading(false);
        }
    }

    const handleInputChange = (e) => {
        setKeyword(e.target.value);
    }
    const handleKeyDown = (e) => {
        if(e.key == 'Enter') {
            e.preventDefault();
            setSearchFormData({
                ...searchFormData,
                pageNum: 1,
                search: keyword
            });
        }
    }
    const onPageChanged = (pageNum) => {
        setSearchFormData({
            ...searchFormData,
            pageNum
        });
    }

    const onAdvisorTypeChanged = (opt) => {
        setSearchFormData({
            ...searchFormData,
            search: '',
            advisorType: opt.value
        })
        setKeyword('');
    }

    const renderAdvisors = () => {
        if(advisors?.length > 0) {
            return advisors.map((advisor, index) => (
                <div className="d-flex cursor-pointer align-items-center mb-4 underline underline-5px" key={index} onClick={e=>gotoAdvisorDetail(advisor)}>
                    <div className="flex-fluid d-flex align-items-center">
                        <img src={getAvatarUrl(advisor.avatar)} className="round-avatar-40" />
                        <div className="ms-2">
                            <div>{advisor.username}</div>
                            <div>
                                <label>Last Chat: </label>
                                <span className="ms-2"><Moment format="YYYY-MM-DD">{advisor.lastChat}</Moment></span>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <MDBIcon fas icon="angle-right" size="lg" />
                    </div>
                </div>
            ))
        } else {
            {loading ? '' : <div className="d-flex justify-content-center align-items-center">
                <SVG src='/img/icons/no_found.svg' style={{height:40, width: 40}} /><div className="ms-2 mt-1">No Chats</div>
            </div>}
        }
    }

    return (
        <MDBContainer breakpoint="lg" className="p-4">
            <MDBCard className="client-my-advisors">
                <MDBCardHeader>
                    <div className="d-flex align-items-center">
                        <MDBIcon fas icon='user-graduate' color="info" size='lg' />
                        <span className="ms-2 fw-bold">Chat History</span>
                    </div>
                </MDBCardHeader>
                <MDBCardBody>
                    <div className="d-flex align-items-center justify-content-end mb-3">
                        <div className="w-100 d-flex justify-content-end" style={{maxWidth: 200}}>
                            <MDBInput 
                                value={keyword}
                                label="Search Advisor"
                                onChange={handleInputChange}
                                onKeyDown={handleKeyDown}
                            />
                        </div>
                        <div className="ms-2" style={{width: 150}}>
                            <Select
                                options={advisorTypeOpts}
                                onChange={(opt)=>onAdvisorTypeChanged(opt)}
                                value={advisorTypeOpts.find(opt=>opt.value == searchFormData.advisorType)}
                            />
                        </div>
                    </div>

                    { renderAdvisors() }

                    <div className='d-flex justify-content-center mt-4'>
                        <Paginator curPage={searchFormData.pageNum} total={total} perPage={searchFormData.perPage} onPageChanged={onPageChanged} />
                    </div>
                </MDBCardBody>
            </MDBCard>
            {loading && <LoadingIndicator/>}
        </MDBContainer>
    )
});

export default MyAdvisors;