import React, { useEffect } from 'react';
import SVG from 'react-inlinesvg';
import styles from './ClientDetail.scss';
import { useNavigate } from 'react-router-dom';
import { MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBCardTitle, MDBCol, MDBContainer, MDBIcon, MDBRow, MDBSpinner, MDBTextArea } from 'mdb-react-ui-kit';
import { floatV, getAvatarUrl, isMobileScreen, text2html, v_url } from '../../../Utils/utils';
import { useState } from 'react';
import Moment from 'react-moment';
import ApiService from '../../../Core/Service/ApiService';
import LoadingIndicator from '../../../Components/LoadingIndicator/LoadingIndicator';
import { useContext } from 'react';
import ModalContext from '../../../Context/ModalContext';
import JwtService from '../../../Core/Service/JwtService';

const AdvisorClientKeyNote = React.memo(props=>{
    const { cId, modalMode } = props;
    const [loading, setLoading] = useState(null);
    const [client, setClient] = useState(null);
    const [keyNote, setKeyNote] = useState('');
    const [earning, setEarning] = useState(null);
    const [saving, setSaving] = useState(false);
    const [editNoteFormData, setEditNoteFormData] = useState(null);
    const modalContext = useContext(ModalContext);
    const navigate = useNavigate();
    const [availCoupon, setAvailCoupon] = useState();

    useEffect(() => {
        if(cId) {
            (async () => {
                setLoading(true);

                // To get client info
                let response = await ApiService.get('/advisor/client/show/'+cId);
                setClient(response.data.user);

                // To get earning from client
                response = await ApiService.post('/advisor/client_earnings', {cIds:[cId]});
                setEarning(response.data.earnings ? response.data.earnings[0] : null);

                // To get notes about client & chats
                response = await ApiService.post('/advisor/note/show', {cId});
                setKeyNote(response.data.note);

                // To get available coupon
                response = await ApiService.get(`/advisor/coupon/available/${cId}`);
                setAvailCoupon(response.data.coupon);

                setLoading(false);
            })();
        }
    }, [cId]);

    const onNoteChanged = (e) => {
        setEditNoteFormData({
            ...editNoteFormData,
            note: e.target.value
        })
    }
    const onEditNoteBtnClicked = (chatId) => {
        setEditNoteFormData({
            cId: cId,
            note: keyNote
        });
    }
    const onCancelBtnClicked = () => {
        setEditNoteFormData(null);
    }
    const handleSaveNote = (e) => {
        e.preventDefault();

        setSaving(true);
        ApiService.post('/advisor/note', editNoteFormData).then(result=>{
            setEditNoteFormData(null);
            setKeyNote(editNoteFormData.note);
        })
        .finally(()=>{
            setSaving(false);
        })
    }

    const onChatOffMessageClicked = async (e) => {
        e.preventDefault();
        try {
            const response = await ApiService.get(`/chatoff_thread/getopen/${cId}`);
            if(isMobileScreen()) {
                JwtService.setChatOffThread(response.data.thread);
                JwtService.setChatOffPartner(client);
                navigate(v_url(`/chatoff_message`));
            } else {
                modalContext.setModalData({
                    thread: response.data.thread,
                    partner: client
                });
                modalContext.setOpenChatOffMessageModal(true);
            }
        } finally {

        }
    }

    return <MDBCard className='client-key-note-card'>
            <MDBCardBody className='p-3'>
                <MDBRow>
                    <MDBCol size={12} md={modalMode ? 12 : 6} lg={modalMode ? 12 : 5} className={`mb-2 ${modalMode ? '' : 'mb-md-0'}`}>
                        <div className='d-flex client-main-info'>
                            <div className='position-relative'>
                                <img className='round-avatar-80' src={getAvatarUrl(client?.avatar)} />
                                {availCoupon && <div className='coupon' style={{left: 'calc(50% - 15px)', top: -10}}>{availCoupon.availMinutes}</div>}
                            </div>
                            <div className='client-maininfo ms-2 ms-lg-4'>
                                <div className='fw-bold' style={{color:'purple'}}>{client?.username}</div>
                                <div>
                                    <span className='label'>Lifetime Earnings:</span>
                                    <span className='fw-bold text-blue1 ms-1'>$ {floatV(earning?.totAmt ?? 0)}</span>
                                </div>
                                <div className=''>
                                    <span className='label'>Last Chat:</span>
                                    <span className='fw-bold text-blue1 ms-1'>
                                        {earning && <Moment format='YYYY-MM-DD'>{earning.lastChat}</Moment>}
                                        {!earning && <span>-</span>}
                                    </span>
                                </div>
                            </div>

                            {!modalMode && <div className="action ms-2">
                                <MDBBtn tag='a' color="none" onClick={onChatOffMessageClicked}>
                                    <SVG className="bell-icon" 
                                        src='/img/icons/envelope.svg' 
                                        width={30} 
                                        height={30} 
                                        fill="#a08aed"
                                        title="Send Message"
                                    />
                                </MDBBtn>
                            </div>}
                        </div>
                    </MDBCol>
                    <MDBCol size={12} md={modalMode ? 12 : 6} lg={modalMode ? 12 : 7} className='key-note-section flex-fluid'>
                        {editNoteFormData && !editNoteFormData.chatId && 
                            <div className='w-100'>
                                <MDBTextArea className='w-100 p-2' name='key_note' value={editNoteFormData.note} rows={3} onChange={onNoteChanged} />
                                <div className='d-flex justify-content-end mt-2'>
                                    <MDBBtn color='second' onClick={onCancelBtnClicked} className='py-1 px-3'>
                                        Cancel
                                    </MDBBtn>
                                    <MDBBtn className='confideas-primary-btn ms-2 py-1 px-3' onClick={handleSaveNote}>
                                        {saving && <div className='d-flex align-items-center'>
                                            <MDBSpinner size='sm' color='info'/>
                                            <span className='ms-2'>Saving</span>
                                        </div>}
                                        {!saving && <span>Save</span>}
                                    </MDBBtn>
                                </div>
                            </div>
                        }
                        {(!editNoteFormData || editNoteFormData.chatId) &&
                            <div className='key-note d-flex flex-column p-2'>
                                <div><strong style={{fontStyle:'italic'}}>Key Notes:</strong></div>
                                <div>
                                    <div dangerouslySetInnerHTML={{ __html: text2html(keyNote)}}></div>
                                </div>
                                <div className='flex-fluid d-flex flex-column justify-content-end'>
                                    <div className='d-flex justify-content-end pb-1'>
                                    {!modalMode && (
                                        <MDBBtn color='none' tag="a" onClick={e=>onEditNoteBtnClicked(null)}>
                                            <MDBIcon fas icon='edit' size='lg' color='warning' className='cursor-pointer' title='Edit'/>
                                        </MDBBtn>
                                    )}
                                    </div>
                                </div>
                            </div>
                        }
                    </MDBCol>
                </MDBRow>
                {loading && <LoadingIndicator />}
            </MDBCardBody>
        </MDBCard>
});

export default AdvisorClientKeyNote;