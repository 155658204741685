import React, { useContext, useEffect } from 'react';
import Select from "react-select";
import SVG from 'react-inlinesvg';
import styles from './ClientDetail.scss';
import { useNavigate } from 'react-router-dom';
import { MDBCard, MDBCardBody, MDBInput, MDBCardHeader, MDBCardTitle, MDBContainer, MDBIcon, MDBSpinner, MDBTextArea, MDBTable, MDBTableHead, MDBTableBody, MDBBtn } from 'mdb-react-ui-kit';
import { formatSeconds, format_time_mm_ss, getAvatarUrl, text2html, v_url } from '../../../Utils/utils';
import { useState } from 'react';
import Moment from 'react-moment';
import ApiService from '../../../Core/Service/ApiService';
import ScrollHorizon from '../../../Components/ScrollHorizon/ScrollHorizon';
import LoadingIndicator from '../../../Components/LoadingIndicator/LoadingIndicator';
import { ChatStatus, ReviewType } from '../../../Constants/constants';
import EventContext from '../../../Context/EventContext';

const AdvisorClientChatHistory = React.memo(props=>{
    const { cId, modalMode } = props;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [chats, setChats] = useState(null);
    const [total, setTotal] = useState(0);
    const perPageOpts = [
        { value: 10,label: "10" },
        { value: 20, label: "20" },
        { value: 30, label: "30" }
    ];
    const [curPerPageOpt, setCurPerPageOpt] = useState(perPageOpts[0]);
    const [searchFormData, setSearchFormData] = useState({
        pageNum: 1,
        perPage: 100,
        searchPartnerId: cId
    });
    const eventContext = useContext(EventContext);

    useEffect(()=>{
        getChatHistory();
    }, [searchFormData, eventContext.evtAdvisorNoteChanged]);

    const onPerPageChanged = (opt) => {
        setSearchFormData({
            ...searchFormData,
            perPage: opt.value,
            pageNum: 1
        });
        setCurPerPageOpt(opt);
    }
    const onPageChanged = (pageNum) => {
        setSearchFormData({
            ...searchFormData,
            pageNum
        });
    }

    const getChatHistory = () => {
        setLoading(true);
        ApiService.post(`/advisor/chat/history`, searchFormData).then(result=>{
            setChats(result.data.chats);
            setTotal(result.data.total);
        })
        .finally(()=>{
            setLoading(false);
        });
    }
    const gotoChatDetail = (chat) => {
        if(modalMode) return;
        navigate(v_url(`/chat/${chat._id}/detail`));
    }

    const renderChats = () => {
        return (
        <section className='w-100 chats-history-table'>
        {(()=>{
            if(chats?.length > 0) {
                return <>
                    { chats.map((chat, index)=>{
                        const partner = chat.cId;
                        return (<div className='mb-2 py-2 underline' onClick={e=>gotoChatDetail(chat)} key={index}>
                            <div className='cursor-pointer d-flex align-items-center' key={index}>
                                <div className="chat-time flex-fluid">
                                    <div className='d-flex align-items-center'>
                                        <div>
                                            {chat.status >= ChatStatus.ENDED && <>
                                            <div className='d-flex align-items-center' style={{color: '#0977d9'}}>
                                                <Moment format="YYYY-MM-DD">{chat.startedAt}</Moment>
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <div className='px-2 chat-earning'>
                                                    $ {chat.ad_earning}
                                                </div>
                                                <div className='ms-2 chat-duration text-center'>
                                                    <span className='ms-1'>{ formatSeconds(chat.duration, true) }</span>
                                                </div>
                                                {chat.reviewId &&
                                                    <img src={`/img/icons/${chat.reviewId.type === ReviewType.HAPPY ? 'png/smile.png' : 'png/sad.png'}`} 
                                                        style={{width:25}} 
                                                        className="cursor-pointer ms-2"
                                                    />
                                                }
                                            </div>
                                            </>}
                                            {chat.status === ChatStatus.MISSED && <>
                                                <div className='d-flex align-items-center' style={{color: '#0977d9'}}>
                                                    <Moment format="YYYY-MM-DD">{chat.createdAt}</Moment>
                                                </div>
                                                <div style={{color: '#ef0000', fontStyle:'italic'}}>Missed</div>
                                            </>}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='d-flex align-items-center justify-content-end'>
                                        {!modalMode && <MDBIcon fas icon="angle-right" size='lg' />}
                                    </div>
                                </div>
                            </div>
                            {chat.advisorNoteId && (
                            <div className='chat-note mt-2 p-2 text-black'
                                dangerouslySetInnerHTML={{ __html: text2html(chat.advisorNoteId.note)}}
                            />
                            )}
                        </div>)
                    })}
                </>
            } else {
                return <>
                    {loading ? '' : <div className="d-flex justify-content-center align-items-center">
                        <SVG src='/img/icons/no_found.svg' style={{height:40, width: 40}} /><div className="ms-2 mt-1">No Chats</div>
                    </div>}
                </>}
        })()}
        </section>
        )
    }

    return <MDBCard className='client-chat-history-section mt-4'>
        <MDBCardHeader>
            <MDBCardTitle>Chat History</MDBCardTitle>
        </MDBCardHeader>
        <MDBCardBody>
            {/* <div className="confideas-table-header">
                <SearchBar>
                    <div className='search-item'>
                        <Select className="per-page"
                            options={perPageOpts} 
                            onChange={(opts) => onPerPageChanged(opts)} 
                            defaultValue={curPerPageOpt} />
                    </div>
                </SearchBar>
            </div> */}

            <ScrollHorizon>
                {renderChats()}
            </ScrollHorizon>

            {/* <div className='d-flex justify-content-center mt-4'>
                <Paginator total={total} perPage={searchFormData.perPage} onPageChanged={onPageChanged} />
            </div> */}

            {loading && <LoadingIndicator />}
        </MDBCardBody>
    </MDBCard>
});

export default AdvisorClientChatHistory;