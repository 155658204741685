import { MDBBtn, MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import React from "react";
import { useNavigate } from "react-router-dom";
import { v_url } from "../../Utils/utils";

function ConsultArea(props) {
    const navigate = useNavigate();

    return (
        <div className="consult-area mb-4">
            <img src={props.imageUrl} className="w-100 cursor-pointer" onClick={e=>navigate(props.url)} />
            <h5 className="mt-4">{props.title}</h5>
            <p className="mt-4 description" style={{height: 100}} >{props.description}</p>
            <div className="mt-4 d-flex justify-content-center">
                <MDBBtn onClick={e=>navigate(props.url)}>Read More</MDBBtn>
            </div>
        </div>
    )
}

function ConsultAreas(props) {
    const consultAreas = [ 
        {
            imageUrl: '/img/others/articles/psychic_reading.jpg',
            title: 'Psychic Reading',
            url: v_url('/blog/psychic_readings'),
            description: 'Have you ever wished to have a GPS to know your paths? We can help you with love, twin flames, and many more.'
        }, {
            imageUrl: '/img/others/articles/commerce.jpg',
            title: 'Commerce and Career',
            url: v_url('/blog/commerce_and_career'),
            description: 'Explore your career paths to increase your influence and prosperity. Enjoy confidential live chats to understand your needs and desires.'
        }, {
            imageUrl: '/img/others/articles/conflict_resolve.jpg',
            title: 'Conflict Resolution',
            url: v_url('/blog/conflict_resolution'),
            description: 'Discover powerful insights for conflict resolution with live chat confidential support available 24/7. Empower you to achieve clarity and peace of mind in even the most complex situations.'
        }, {
            imageUrl: '/img/others/articles/vitality.jpg',
            title: 'Vitality and Wellbeing',
            url: v_url('/blog/vitality_and_wellbeing'),
            description: 'Whether you are seeking transformative growth or healing, recognizing the balance of body, mind, and spirit will help achieve holistic wellness.'
        }
    ];

    return (
        <div className="consult-area-container">
            <MDBContainer breakpoint="lg">
                <MDBRow>
                    { 
                        consultAreas.map((ca, index) => {
                            return (
                                <MDBCol lg='3' sm='6' key={index}>
                                    <ConsultArea title={ca.title} imageUrl={ca.imageUrl} description={ca.description} url={ca.url} />
                                </MDBCol>
                            )
                        })
                    }
                </MDBRow>
            </MDBContainer>
        </div>
    )
}

export default ConsultAreas;