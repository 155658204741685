import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { purgeAuth } from "../Store/Reducer/authReducer";
import { useNavigate } from "react-router-dom";
import { removeChangedAdvisors } from "../Store/Reducer/changedAdvisorReducer";
import { removeAlarms } from "../Store/Reducer/alarmReducer";
import { v_url } from "../Utils/utils";

const Logout = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(()=>{
        dispatch(purgeAuth());
        dispatch(removeChangedAdvisors());
        dispatch(removeAlarms());
        navigate(v_url(`/login`));
    }, [])
    return <div className="d-flex justify-content-center align-items-center w-100" style={{height: 300}}>
        Logging Out...
    </div>
}

export default Logout;