import React from 'react';
import SVG from 'react-inlinesvg';
import styles from './ClientDetail.scss';
import { MDBBtn, MDBContainer } from 'mdb-react-ui-kit';
import AdvisorClientKeyNote from './ClientKeyNote';
import AdvisorClientChatHistory from './ClientChatHistory';
import { useEffect } from 'react';
import { scrollUpTop, v_url } from '../../../Utils/utils';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getPreviousUrl, popHistory } from '../../../Store/Reducer/urlHistoryReducer';

const AdvisorClientDetail = React.memo(props=>{
    const dispath = useDispatch();
    const previousUrl = useSelector(getPreviousUrl)
    const params = useParams();
    const cId = params.cId;
    const navigate = useNavigate();

    useEffect(()=>{
        scrollUpTop();
    }, [])

    return <MDBContainer breakpoint='lg' className='advisor-client-detail p-4'>
        <div className='mb-4'>
            <MDBBtn color='second' className='d-flex align-items-center' onClick={e=>navigate(v_url('/advisor/dashboard'))}>
                <SVG src='/img/icons/angle-left.svg' width={15} height={15} fill='#555' />
                <span className='ms-2'>Back</span>
            </MDBBtn>
        </div>

        <AdvisorClientKeyNote cId={cId} />
        <AdvisorClientChatHistory cId={cId} />
    </MDBContainer>
});

export default AdvisorClientDetail;