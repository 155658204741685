import axios from "axios";
import { toast } from 'react-toastify';
import JwtService from "./JwtService";
import { v_url } from "../../Utils/utils";

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL + '/api';
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";


class ApiService {
  navigate;

  static setHeader(token) {
    token = JwtService.getAuthToken();
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }

  static setNavigate(n) {
    ApiService.navigate = n
  }

  static handleError(error) {
    if(error.response?.status === 401) {
      ApiService.navigate && ApiService.navigate(v_url('/logout'));
    } else {
      let message = error.response?.data?.message || error.response?.data?.error?.message;
      if(message) toast.warning(message);
    }
  }

  static query(resource, params) {
    ApiService.setHeader();

    return new Promise((resolve, reject) => {
      axios.get(resource, params).then( result =>{
        resolve(result);
      })
      .catch( error => {
        this.handleError(error);
        reject(error);
      })
    })
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  static get(resource, params) {
    ApiService.setHeader();

    let url = "";
    if(params) {
      url = resource + "?";
      Object.keys(params).forEach(key=>{
        url += `&${key}=${params[key]}`;
      })
    } else {
      url = resource;
    }

    return new Promise((resolve, reject) => {
      axios.get(url).then((result) => {
        resolve(result);
      })
      .catch((error) => {
        this.handleError(error);
        reject(error);
      })
    });
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  static post(resource, params, headers=null, onUploadProgress){
    ApiService.setHeader();

    const config = {
      timeout: 1000000,
      onUploadProgress: function(progressEvent) {
        onUploadProgress && onUploadProgress(progressEvent);
      }
    }
    if(headers) config.headers = headers;
  
    return new Promise((resolve, reject) => {
      axios.post(`${resource}`, params, config)
      .then((result) => {
        return resolve(result);
      })
      .catch((error) => {
        this.handleError(error);
        reject(error);
      })
    })
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  static patch(resource, params) {
    ApiService.setHeader();

    return new Promise((resolve, reject) => {
      axios.patch(resource, params).then((result) => {
        resolve(result);
      })
      .catch((error) => {
        this.handleError(error);
        reject(error);
      })
    })
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  static put(resource, params) {
    ApiService.setHeader();

    return new Promise((resolve, reject) => {
      axios.put(`${resource}`, params).then((result) => {
        resolve(result);
      })
      .catch((error) => {
        this.handleError(error);
        reject(error);
      })
    })
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  static delete(resource) {
    ApiService.setHeader();

    return new Promise((resolve, reject) => {
      axios.delete(resource).then((result) => {
        return resolve(result);
      })
      .catch((error) => {
        this.handleError(error);
        reject(error);
      })
    })
  }
}

export default ApiService;
