import React, {useEffect, useRef, useState, useContext} from "react";
import { Link, useNavigate } from 'react-router-dom';
import styles from './Modal.scss';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { setAuth } from '../../Store/Reducer/authReducer';
import { containsSpecialLetters, validateForm } from '../../Utils/utils';
import ApiService from "../../Core/Service/ApiService";
import { 
    MDBModal,
    MDBModalDialog,
    MDBModalBody,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBBtn,
    MDBSpinner,
    MDBInput,
    MDBIcon
} from "mdb-react-ui-kit";
import ModalContext from "../../Context/ModalContext";
import { removeChat } from "../../Store/Reducer/chatReducer";
import { removeAlarms } from "../../Store/Reducer/alarmReducer";

const SignupModal = React.memo((props) => {
    const modalContext = useContext(ModalContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [signingup, setSigningup] = useState(false);
    const unameInputRef = useRef(null);
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: '',
        checkPassword: ''
    })
    const signupFrmRules = {
        username: {
            required: 'string'
        },
        email: {
            required: 'email',
        },
        password: {
            required: 'string',
            minlength: 6
        },
        checkPassword: {
            required: 'string',
            minlength: 6,
            equal: 'password',
            messages: {
                'required': 'Check password is required.',
                'equal': 'Inconsistent Password'
            }
        }
    }
    const [inputErrors, setInputErrors] = useState({});
    const toggleModal = () => {
        formData.username= '';
        formData.email= '';
        formData.password= '';
        formData.checkPassword = '';
        setInputErrors({});

        modalContext.setOpenSignUpModal(false);
    }
    const toggleOpenSignInModal = (e) => {
        e.preventDefault();
        modalContext.setOpenSignUpModal(false);
        modalContext.setOpenSignInModal(true);
    }
    useEffect(()=>{
        unameInputRef.current.focus();
    }, [])
    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }
    const checkUsername = (username) => {
        return (/^[a-zA-Z][a-zA-Z0-9-_]*$/.test(username));
    }
    const handleSignup = (e) => {
        e.preventDefault();

        dispatch(removeChat());
        dispatch(removeAlarms());

        formData.username = formData.username.trim();
        formData.email = formData.email.trim();

        let valid_ret = validateForm(formData, signupFrmRules);
        if(valid_ret.result === true) {
            if(!checkUsername(formData.username)) {
                setInputErrors({
                    username: 'Please enter the email address in the correct format.'
                })
                return;
            }

            setInputErrors({});

            // To check whether or not username contains special letters
            if(containsSpecialLetters(formData.username)) {
                setInputErrors({
                    username: 'Username could not contain special letters'
                });
                return;
            }

            setSigningup(true);

            ApiService.post('/client/auth/signup', formData).then(result => {
                toast.success('Signup with success');
                dispatch(setAuth({
                    authUser: result.data.authUser,
                    authToken: result.data.authToken
                })) 
                modalContext.setOpenSignUpModal(false);
            })
            .catch(error => {

            })
            .finally(()=>{
                setSigningup(false);
            });
        } else {
            setInputErrors(valid_ret.errors);
        }
    }

    return (
        <MDBModal open={modalContext.openSignUpModal} setOpen={modalContext.setOpenSignUpModal} tabIndex='-1' className="client-signup-modal" >
            <MDBModalDialog>
                <MDBModalContent>
                    <MDBModalHeader className='d-flex justify-content-center'>
                        <MDBBtn className='btn-close' 
                                tabIndex={-1}
                                color='none' 
                                onClick={toggleModal}></MDBBtn>
                    </MDBModalHeader>
                    <MDBModalBody className='d-flex p-4 flex-column justify-content-center align-items-center'>
                        <div className="text-center">
                            <h5>Welcome to Confideas</h5>
                            <span>Chat for insights! Confide ideas!</span>
                        </div>
                        <form className='d-flex w-80 flex-column'>
                            <div className='mt-4'>
                                <MDBInput   type='text' 
                                            name='username'
                                            label="Username" 
                                            ref={unameInputRef} 
                                            tabIndex={1}
                                            value={formData.username} 
                                            onChange={handleInputChange}/>
                                <div className="username-rule">
                                Username must start with a letter, and consist of letters, numbers, underscores(_) or hyphens(-).
                                </div>
                                {inputErrors.username && <div className='error'>{inputErrors.username}</div>}
                            </div>
                            <div className='mt-4'>
                                <MDBInput type='email' 
                                            label="Email"
                                            name='email'
                                            value={formData.email}
                                            tabIndex={2}
                                            onChange={handleInputChange}
                                />
                                {inputErrors.email && <div className='error'>{inputErrors.email}</div>}
                            </div>
                            <div className='mt-4'>
                                <MDBInput   type='password' 
                                            label="Password" 
                                            name='password'
                                            tabIndex={3}
                                            value={formData.password}
                                            autoComplete="off"
                                            onChange={handleInputChange}
                                />
                                {inputErrors.password && <div className='error'>{inputErrors.password}</div>}
                            </div>
                            <div className='mt-4'>
                                <MDBInput type='password' 
                                            label="Confirm Password" 
                                            name='checkPassword'
                                            tabIndex={4}
                                            value={formData.checkPassword}
                                            autoComplete="off"
                                            onChange={handleInputChange}
                                />
                                {inputErrors.checkPassword && <div className='error'>{inputErrors.checkPassword}</div>}
                            </div>
                            <div className='mt-4'>
                                <MDBBtn 
                                    tabIndex='5'
                                    type='submit'
                                    style={{
                                        backgroundColor: 'rgb(251, 233, 83)',
                                        color: '#111',
                                        width: '100%'
                                    }}
                                    onClick={handleSignup}
                                >
                                    {signingup && <>
                                        <MDBSpinner size='sm' role='status' tag='span' className='me-2' />
                                        Signing up
                                    </>}
                                    {!signingup && <span style={{textTransform:'none'}}>Create Account</span>}
                                    
                                </MDBBtn>
                            </div>
                            <div className='mt-4'>
                                <span>Already have an account?</span>
                                <a  tabIndex={-1}
                                    onClick={toggleOpenSignInModal} 
                                    className='mx-2' 
                                    style={{
                                        color: 'rgb(129, 28, 145)',
                                        cursor: 'pointer'
                                    }}
                                >
                                    Log in <strong>here</strong>
                                </a>
                            </div>
                        </form>
                    </MDBModalBody>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    )

}, (oldProps, newProps) => {
    return oldProps.open === newProps.open;
});

export default SignupModal;