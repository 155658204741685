import React from "react";
import classname from 'classnames';
import styles from './Advisor.scss'
import { MDBBtn, MDBCol, MDBIcon, MDBRow, MDBSpinner } from "mdb-react-ui-kit";
import { getAvatarUrl, v_url } from "../../Utils/utils";
import { IsAuthenticated, IsFavoriteAdvisor, addFavoriteAdvisor, memoizedGetAuthUser, removeFavoriteAdvisor } from "../../Store/Reducer/authReducer";
import { IsChatSocketConnected, getChatSocket } from "../../Store/Reducer/socketReducer";
import { useDispatch, useSelector } from "react-redux";
import SVG from 'react-inlinesvg';
import { toast } from "react-toastify";
import { MIN_CHAT_TIME, SendMessages, TEST_MODE, UserRole } from "../../Constants/constants";
import { useNavigate } from "react-router-dom";
import { memoizedGetChat } from "../../Store/Reducer/chatReducer";
import ApiService from "../../Core/Service/ApiService";
import { useState } from "react";
import LoadingIndicator from '../../Components/LoadingIndicator/LoadingIndicator'
import { useContext } from "react";
import ModalContext from "../../Context/ModalContext";
import JwtService from "../../Core/Service/JwtService";
import { getChangedAdvisors, getChangedAdvisorsHash256 } from "../../Store/Reducer/changedAdvisorReducer";
import { useEffect } from "react";

function Advisor(props) {
    const modalContext = useContext(ModalContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const chatSocket = useSelector(getChatSocket);
    const chat = useSelector(memoizedGetChat);
    const { advisor } = props;
    const aId = advisor._id;
    const [advisorInfo, setAdvisorInfo] = useState(advisor);
    const [addingFavorite, setAddingFavorite] = useState(false);
    const isFavoriteAdvisor = useSelector((state) => IsFavoriteAdvisor(state, aId))
    const isAuthenticated = useSelector(IsAuthenticated);
    const authUser = useSelector(memoizedGetAuthUser);
    const changedAdvisorHash = useSelector(getChangedAdvisorsHash256);
    const changedAdvisors = useSelector(getChangedAdvisors);
    const [availCoupon, setAvailCoupon] = useState(null);
    const [checkingBuzz, setCheckingBuzz] = useState(false);
    const [checkingAvailCoupon, setCheckingAvailCoupon] = useState(false);

    useEffect(()=>{
        if(advisor) updateAdvisorInfo(advisor);
    }, [advisor]);

    useEffect(()=>{
        if(isAuthenticated) {
            if(props.availCoupon !== undefined) {   // if props.availCoupon is not undefined, it indicates parent component alreay searched coupons for this advisor
                setAvailCoupon(props.availCoupon);
            } else {
                setCheckingAvailCoupon(true);
                ApiService.get('/client/coupon/available/' + aId).then(result=>{
                    setAvailCoupon(result.data.coupon);
                })
                .finally(()=>{
                    setCheckingAvailCoupon(false);
                })
            }
        }
    }, [props.availCoupon, isAuthenticated])

    useEffect(()=>{
        if(changedAdvisorHash) {
            const chgAdvisor = changedAdvisors.find(a=>a._id === aId);
            if(chgAdvisor) updateAdvisorInfo(chgAdvisor);
        }
    }, [changedAdvisorHash])

    const updateAdvisorInfo = (adInfo) => {
        const info = {...adInfo};
        if(info.isLoggedIn != true) {
            info.serviceChatStatus = 'offline';
        } else {
            if(info.isBusy) {
                info.serviceChatStatus = 'busy';
            } else {
                info.serviceChatStatus = info.isAvailableChat ? 'online' : 'offline';
            }
        }
        setAdvisorInfo(info);
    }

    const onBuzzBtnClicked = (e) => {
        e.preventDefault();

        if(!isAuthenticated) {
            modalContext.setOpenSignInModal(true);
            return;
        }
        if(authUser?.role !== UserRole.CLIENT) {
            toast.warn("Only clients could use this service");
            return;
        }

        setCheckingBuzz(true);
        ApiService.post('/client/buzz/can_send', {aId: advisor._id}).then(result=>{
            if(!result.data.canSend) {
                toast.error("You have alreay sent a message within an hour");
                return;
            }

            modalContext.setModalData({advisor})
            modalContext.setOpenSendBuzzModal(true);
        })
        .finally(()=>{
            setCheckingBuzz(false);
        })
    }

    const onChatBtnClicked = (e) => {
        e.preventDefault();
        
        if(!isAuthenticated) {
            modalContext.setOpenSignInModal(true);
            return;
        }
        if(chat?.status && chat.status != 'none') {
            toast.error('You already created a chat.')
            return;
        }
        if(authUser?.role != UserRole.CLIENT) {
            toast.warn("Only clients could use this service");
            return;
        }

        if(!TEST_MODE && !availCoupon && authUser.balance < (MIN_CHAT_TIME-1) * advisorInfo.rate_per_min_chat) {
            // if client's balance is less than limit, then goto payment page.
            // Upon payment made with success, it would emit request_chat message.
            JwtService.setPaymentRequest({
                purpose: 'request_chat',
                advisor: advisorInfo,
            });
            modalContext.setOpenPayAmountModal(true);
        } else {
            modalContext.setModalData({advisor: advisorInfo});
            modalContext.setOpenEnoughBalanceModal(true);
        }
    }

    const onMainInfoBlockClicked = () => {
        navigate(v_url('/psychic/' + advisor.username.replaceAll(' ', '-')));
    }

    const onAddFavorite = (e) => {
        e.preventDefault();

        setAddingFavorite(true);
        ApiService.post('/client/advisor/favorite/add', {aId}).then(result => {
            dispatch(addFavoriteAdvisor(aId));
        })
        .catch(error => {

        })
        .finally(()=>{
            setAddingFavorite(false);
        })
    }

    const onRemoveFavorite = (e) => {
        e.preventDefault();

        setAddingFavorite(true);
        ApiService.post('/client/advisor/favorite/remove', {aId}).then(result => {
            dispatch(removeFavoriteAdvisor(aId));
        })
        .catch(error => {

        })
        .finally(()=>{
            setAddingFavorite(false);
        })
    }

    const renderNormal = () => {
        return (
            <>
                <div className="main-info d-flex flex-column justify-content-center align-items-begin" onClick={onMainInfoBlockClicked} >
                    <div className="avatar-container">
                        <div 
                            className={classname("avatar", advisorInfo?.isLoggedOn ? 'online' : 'offline')}
                            style={{backgroundImage: `url(${(getAvatarUrl(advisorInfo?.avatar))})`}}
                        />
                    </div>
                    <div className='px-2 mt-2'>
                        <div className="advisor-name">{advisorInfo?.username}</div>
                        <div className="service-name mt-1">{advisorInfo.service_name}</div>
                    </div>
                    {availCoupon &&
                    <div className="d-flex justify-content-center align-items-center coupon" title={`Free ${availCoupon.availMinutes} mins`}>
                        {availCoupon.availMinutes}
                    </div>
                    }
                </div>
    
                <div className='px-2 mt-2'>
                    <div className="d-flex comm-services">
                        <div className={`me-1 w-100`}>
                            <MDBBtn className={`me-1 w-100 chat-btn ${advisorInfo.serviceChatStatus}`}
                                    onClick={onChatBtnClicked} 
                                    title="Live Chat"
                                    disabled={advisorInfo.serviceChatStatus != 'online' || checkingAvailCoupon}>
                                <img src='/img/icons/message.svg' alt='Live Chat' />
                                <span className="ms-2" style={{textTransform: 'none'}}>${advisorInfo?.rate_per_min_chat} / min</span>
                            </MDBBtn>
                        </div>
                    </div>
                </div>
    
                <div className="text-gray1 ps-2 fs-90 mt-2">
                    {advisorInfo?.total_chats ?? 0} Readings
                </div>

                <div className="ps-2 fs-90 hidden">
                    <span className="text-gray1">Active : </span>
                    <span>
                        {(()=>{
                            if(advisorInfo.isLoggedIn && advisorInfo.isAvailableChat) {
                                if(advisorInfo.online || !advisorInfo.lastActiveTime) return 'now';
                                else return advisorInfo.lastActiveTime + ' ago';
                            } else {
                                return 'Offline';
                            }
                        })()}
                    </span>
                </div>

                {isAuthenticated && authUser?.role === UserRole.CLIENT &&
                    <div className="advisor-action">
                        { isFavoriteAdvisor && 
                            <div onClick={onRemoveFavorite} 
                                className="trash-favorite-btn d-flex justify-content-center align-items-center"
                                title="Remove from favorite advisor"
                            >
                                <MDBIcon fas icon='heart' color="danger" size="lg" />
                            </div> 
                        }
                        { !isFavoriteAdvisor && 
                            <div onClick={onAddFavorite} 
                                className="add-favorite-btn d-flex justify-content-center align-items-center"
                                title="Add to favorite advisor"
                            >
                                <MDBIcon far icon='heart' color='danger' size="lg" />
                            </div>
                        }
                    </div>
                }
    
                {addingFavorite && <LoadingIndicator />}
            </>
        )
    }
    const renderSmall = () => {
        return <>
            <div className='avatar d-flex align-items-center'>
                <img src={getAvatarUrl(advisorInfo?.avatar)} />
                {availCoupon &&
                <div className="d-flex justify-content-center align-items-center coupon" title={`Free ${availCoupon.availMinutes} mins`}>
                    {availCoupon.availMinutes}
                </div>
                }
                {isAuthenticated && authUser?.role === UserRole.CLIENT &&
                    <div className="advisor-action">
                        { isFavoriteAdvisor && 
                            <div onClick={onRemoveFavorite} 
                                className="trash-favorite-btn d-flex justify-content-center align-items-center"
                                title="Remove from favorite advisor"
                            >
                                <MDBIcon fas icon='heart' color="danger" size="lg" />
                            </div> 
                        }
                        { !isFavoriteAdvisor && 
                            <div onClick={onAddFavorite} 
                                className="add-favorite-btn d-flex justify-content-center align-items-center"
                                title="Add to favorite advisor"
                            >
                                <MDBIcon far icon='heart' color='danger' size="lg" />
                            </div>
                        }
                    </div>
                }
            </div>
            <div className='px-4 mt-2 flex-fluid'>
                <div className="intro">
                    <strong>{advisorInfo?.username}</strong>
                </div>
                <div className="service-name">
                    {advisorInfo.service_name}
                </div>
                <div className="readings">
                    <strong>{advisorInfo?.total_chats ?? 0}</strong> Readings
                </div>
                <div className="service-rating">
                    <div>${advisorInfo?.rate_per_min_chat}/min</div>
                </div>
            </div>
            <div className='mt-1 px-2 d-flex align-items-center'>
                <div className="comm-services">
                    <div className='chat me-1 w-100'>
                        <MDBBtn className={`me-1 p-2 w-100 chat-btn ${advisorInfo.serviceChatStatus}`}
                                onClick={onChatBtnClicked} 
                                title="Start chat"
                                disabled={advisorInfo.serviceChatStatus != 'online' || checkingAvailCoupon}>
                            <img src='/img/icons/message.svg' alt='Chat' />
                            <span className="ms-2">Chat</span>
                        </MDBBtn>
                    </div>

                    {advisorInfo.serviceChatStatus != 'online' &&
                    <div className="buzz w-100 mt-2">
                        <MDBBtn className={`buzz-btn mb-2 w-100`} 
                                color="info"
                                outline
                                disabled={checkingBuzz}
                                title="Request a chat to advisor"
                                onClick={onBuzzBtnClicked} >
                            <SVG src='/img/icons/notification.svg' 
                                height={19} 
                            />
                        </MDBBtn>
                    </div>
                    }
                </div>
            </div>
            {addingFavorite && <LoadingIndicator />}
        </>
    }

    return <>
        <div className="flex-column advisor-preview normal p-2">
            { renderNormal() }
        </div>
        {/* <div className="d-flex d-sm-none advisor-preview small p-2 px-3">
            { renderSmall() }
        </div> */}
    </>
}

export default Advisor;