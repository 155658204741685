import { MDBBtn, MDBContainer } from "mdb-react-ui-kit";
import React from "react";
import MyBalance from "./MyBalance";
import Breadcrumb from "../../../Components/BreadCrumb/BreadCrumb";
import styles from './MyWallet.scss';
import ClientTransactions from "./MyTransactions";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPreviousUrl, popHistory } from "../../../Store/Reducer/urlHistoryReducer";

const ClientMyWallet = React.memo((props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const previousUrl = useSelector(getPreviousUrl);

    const goBack = (e) => {
        if(previousUrl) {
            const url = previousUrl;
            dispatch(popHistory());
            navigate(url)
        }
    }

    return <MDBContainer breakpoint="lg" className="client-my-wallet-container p-4">
        <MyBalance />
        <ClientTransactions />
    </MDBContainer>
});

export default ClientMyWallet;