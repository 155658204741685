import React, { useEffect, useState } from "react";
import SVG from 'react-inlinesvg';
import { useNavigate } from "react-router-dom";
import ApiService from "../../../Core/Service/ApiService";
import { MDBCard, MDBCardBody, MDBCardHeader, MDBCardTitle, MDBIcon } from "mdb-react-ui-kit";
import ScrollHorizon from "../../../Components/ScrollHorizon/ScrollHorizon";
import LoadingIndicator from "../../../Components/LoadingIndicator/LoadingIndicator";
import Moment from "react-moment";
import { ChatStatus, ReviewType } from "../../../Constants/constants";
import { floatV, formatSeconds, format_time_mm_ss, v_url } from "../../../Utils/utils";

const ClientAdvisorChatHistory = React.memo((props) => {
    const {aId} = props;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [chats, setChats] = useState(null);
    const [total, setTotal] = useState(0);

    useEffect(()=>{
        getChatHistory();
    }, [aId]);

    const getChatHistory = () => {
        setLoading(true);
        const data = {
            pageNum: 1,
            perPage: 100,
            searchPartnerId: aId
        }
        ApiService.post(`/client/chat/history`, data).then(result=>{
            setChats(result.data.chats);
            setTotal(result.data.total);
        })
        .finally(()=>{
            setLoading(false);
        });
    }

    const gotoChatDetail = (chat) => {
        navigate(v_url(`/chat/${chat._id}/detail`));
    }

    const renderChats = () => {
        return (
            <section className="w-100 chats-history-table">
            {(()=>{
                if(chats?.length > 0) {
                    return <>
                        {chats.map((chat, index)=>{
                            return <div className="mb-2 py-2 underline" onClick={e=>gotoChatDetail(chat)} key={index}>
                                <div className='cursor-pointer d-flex align-items-center' key={index}>
                                    <div className="chat-time flex-fluid">
                                        <div className='d-flex align-items-center'>
                                            <div>
                                                {chat.status >= ChatStatus.ENDED && <>
                                                    <div className='' style={{color: '#0977d9'}}>
                                                        <Moment format="YYYY-MM-DD">{chat.startedAt}</Moment>
                                                    </div>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='px-2 chat-amount'>$ {floatV(chat.amount)}</div>
                                                        <div className='ms-2 chat-duration text-center'>
                                                            <span className='ms-1'>{ formatSeconds(chat.duration, true) }</span>
                                                        </div>
                                                        {chat.reviewId &&
                                                            <img src={`/img/icons/${chat.reviewId.type === ReviewType.HAPPY ? 'png/smile.png' : 'png/sad.png'}`} 
                                                                style={{width:25}} 
                                                                className="cursor-pointer ms-2"
                                                            />
                                                        }
                                                    </div>
                                                </>}
                                                {chat.status === ChatStatus.MISSED && <>
                                                    <div className='' style={{color: '#0977d9'}}>
                                                        <Moment format="YYYY-MM-DD">{chat.createdAt}</Moment>
                                                    </div>
                                                    <div style={{color: '#ef0000', fontStyle:'italic'}}>Missed</div>
                                                </>}
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='d-flex align-items-center justify-content-end'>
                                            {<MDBIcon fas icon="angle-right" size='lg' />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })}
                    </>
                } else {
                    return <>
                        {loading ? '' : <div className="d-flex justify-content-center align-items-center">
                            <SVG src='/img/icons/no_found.svg' style={{height:40, width: 40}} /><div className="ms-2 mt-1">No Chats</div>
                        </div>}
                    </>
                }
            })()}
            </section>
        )
    }

    return (
        <MDBCard className='advisor-chat-history-section mt-4'>
            <MDBCardHeader>
                <MDBCardTitle>Chat History</MDBCardTitle>
            </MDBCardHeader>
            <MDBCardBody>
                <ScrollHorizon>
                    {renderChats()}
                </ScrollHorizon>
                {loading && <LoadingIndicator />}          
            </MDBCardBody>
        </MDBCard>
    )
});

export default ClientAdvisorChatHistory;