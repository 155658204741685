import { MDBContainer, MDBTable, MDBTableBody, MDBTableHead, MDBInput, MDBIcon, MDBBtn, MDBTooltip, MDBPopover, MDBPopoverBody } from "mdb-react-ui-kit";
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Moment from "react-moment";
import Paginator from "../../../Components/Paginator/Paginator"
import ApiService from "../../../Core/Service/ApiService";
import LoadingIndicator from "../../../Components/LoadingIndicator/LoadingIndicator";
import { compareDates, getAvatarUrl, getTimeDiff, text2html } from "../../../Utils/utils";
import { memoizedGetAuthUser } from "../../../Store/Reducer/authReducer";
import ScrollHorizon from "../../../Components/ScrollHorizon/ScrollHorizon";
import SVG from 'react-inlinesvg';
import SearchBar from "../../../Components/SearchBar/SearchBar";

const AdvisorAllCoupons = React.memo((props) => {
    const navigate = useNavigate();
    const authUser = useSelector(memoizedGetAuthUser);
    const [loading, setLoading] = useState(false);
    const [coupons, setCoupons] = useState(null);
    const [total, setTotal] = useState(0);
    const perPageOpts = [
        { value: 10,label: "10" },
        { value: 20, label: "20" },
        { value: 30, label: "30" }
    ];
    const [curPerPageOpt, setCurPerPageOpt] = useState(perPageOpts[0]);
    const statusOpts = [
        { value: 'all', label: 'All'},
        { value: 'consumed', label: 'Consumed'},
        { value: 'available', label: 'Available'},
        { value: 'expired', label: 'Expired'}
    ];
    const [curStatusOpt, setCurStatusOpt] = useState(statusOpts[0]);
    const [search, setSearch] = useState('');
    const [searchFormData, setSearchFormData] = useState({
        search: '',
        pageNum: 1,
        perPage: 10,
        status: 'all'
    });

    const onPerPageChanged = (opt) => {
        setSearchFormData({
            ...searchFormData,
            perPage: opt.value,
            pageNum: 1
        });
        setCurPerPageOpt(opt);
    }
    const onStatusChanged = (opt) => {
        setSearchFormData({
            ...searchFormData,
            status: opt.value,
            pageNum: 1
        })
        setCurStatusOpt(opt);
    }
    const onPageChanged = (pageNum) => {
        setSearchFormData({
            ...searchFormData,
            pageNum
        });
    }
    const onSearchClientChanged = (e) => {
        setSearch(e.target.value);
    }
    const onSearchClientKeyPress = (e) => {
        if(e.key == 'Enter') {
            e.preventDefault();
            setSearchFormData({
                ...searchFormData,
                pageNum: 1,
                search
            });
        }
    }
    const onSearchBtnClicked = () => {
        setSearchFormData({
            ...searchFormData,
            pageNum: 1,
            search
        });
    }
    useEffect(()=>{
        getCoupons();
    }, [searchFormData]);

    const getCoupons = () => {
        setLoading(true);

        ApiService.post(`/advisor/coupon/index`, searchFormData).then(result=>{
            setCoupons(result.data.coupons.map(coupon => {
                if(coupon.freeMinutes > 0) {
                    const now = new Date();
                    if(coupon.availMinutes === 0) {
                        coupon.status = 'consumed';
                    } else {
                        if(compareDates(new Date(coupon.expireAt), now) == 1) {
                            coupon.status = 'available';
                            coupon.availDays = getTimeDiff(new Date(coupon.expireAt), now);
                        } else {
                            coupon.status = 'expired';
                        }
                    }
                }
                return coupon;
            }));
            setTotal(result.data.total);
        })
        .finally(error=>{
            setLoading(false);
        })
    }
    const goBack = (e) => {
        e.preventDefault();
        navigate(-1);
    }

    const renderCouponsPC = (coupons) => {
        return (
        <section className="d-none d-sm-block">
            <MDBTable align='middle' className="confideas-table">
                <MDBTableHead>
                    <tr>
                        <th scope="col">Date</th>
                        <th scope='col'>Client</th>
                        <th scope="col">Message</th>
                    </tr>    
                </MDBTableHead>
                <MDBTableBody>
                    {(()=>{
                        if(coupons?.length > 0) {
                            return coupons.filter(coupon=>coupon.cId ? true : false).map((coupon, index) => (
                                <tr key={index}>
                                    <td className="sent-at"><Moment format="YYYY-MM-DD">{coupon.createdAt}</Moment></td>
                                    <td className="client">
                                        <div className='d-flex align-items-center'>
                                            <img
                                                src={ getAvatarUrl(coupon.cId?.avatar)}
                                                alt=''
                                                className='round-avatar-40'
                                            />
                                            <span className="mx-2">{coupon.cId?.username}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <MDBTooltip 
                                            wrapperProps={{ color: 'link' }}
                                            wrapperClass="p-0"
                                            title={<div className="text-left">
                                                <div style={{color: '#31ff00'}}>I wrote</div>
                                                <div
                                                    className="font-italic" 
                                                    dangerouslySetInnerHTML={{ __html: text2html(coupon.message ? coupon.message : 'No message')}}>
                                                </div>
                                            </div>}
                                        >
                                            <div className="d-flex align-items-center message-column">
                                                {coupon.freeMinutes > 0 && 
                                                    <div className={`fs-14px free-minutes-coupon ${coupon.status}`} style={{textTransform:'none'}}>
                                                        {(()=>{
                                                            switch(coupon.status){
                                                                case 'available':
                                                                    return <>
                                                                    <div className="me-2 d-flex align-items-center">
                                                                        <SVG  src={`/img/icons/envelop_unchecked.svg`} width={30} height={25} className="me-2" />
                                                                        <span className="fw-bold" >{ coupon.availMinutes }</span>
                                                                        <span className="mx-1">out of </span>
                                                                        <span className="fw-bold">{coupon.freeMinutes}</span>
                                                                        <span className="ms-1"> mins available</span>
                                                                    </div>
                                                                    <div className="comment text-left" style={{textTransform: 'none'}}>
                                                                        <span>(Exp date : </span> 
                                                                        <Moment format="YYYY-MM-DD">{coupon.expireAt}</Moment>
                                                                        <span>)</span>
                                                                    </div>
                                                                    </>;
                                                                case 'consumed':
                                                                    return <>
                                                                        <div className="me-2 d-flex align-items-center">
                                                                            <SVG  src={`/img/icons/envelop_unchecked.svg`} width={30} height={25} className="me-2" />
                                                                            <span>{coupon.freeMinutes} free mins</span>
                                                                        </div>
                                                                        <div className="comment text-left" style={{textTransform: 'none'}}>(consumed)</div>
                                                                    </>;
                                                                case 'expired':
                                                                    return <>
                                                                        <div className="me-2 d-flex align-items-center">
                                                                            <SVG  src={`/img/icons/envelop_unchecked.svg`} width={30} height={25} className="me-2" />
                                                                            <span>{coupon.freeMinutes} free mins</span>
                                                                        </div>
                                                                        <div className="comment text-left" style={{textTransform: 'none'}}>(expired)</div>
                                                                    </>;
                                                            }
                                                        })()}
                                                    </div>
                                                }
                                                {coupon.freeMinutes == 0 && 
                                                    <div className="fs-14px no-free-minutes-coupon text-gray1" style={{textTransform:'none'}}>
                                                        <SVG src='/img/icons/envelop_unchecked.svg' width={30} height={25} className="me-2"/>
                                                    </div>
                                                }
                                            </div>
                                        </MDBTooltip>
                                    </td>
                                </tr>
                            ))
                        } else {
                            return <tr><td colSpan={6} className="text-center">
                                {loading ? '' : <div className="d-flex justify-content-center align-items-center">
                                    <SVG src='/img/icons/no_found.svg' style={{height:40, width: 40}} /><div className="ms-2 mt-1">No Messages</div>
                                </div>}
                            </td></tr>
                        }
                    })()}
                </MDBTableBody>
            </MDBTable>
        </section>
        )
    }
    const renderCouponsMobile = (coupons) => {
        return (
        <section className="d-block d-sm-none">
            <MDBTable align='middle' className="confideas-table">
                <MDBTableHead>
                    <tr>
                        <th scope='col'>Client</th>
                        <th scope="col">Message</th>
                    </tr>    
                </MDBTableHead>
                <MDBTableBody>
                    {(()=>{
                        if(coupons?.length > 0) {
                            return coupons.filter(coupon=>coupon.cId ? true : false).map((coupon, index) => (
                                <tr key={index}>
                                    <td className="client">
                                        <div className='d-flex align-items-center'>
                                            <img
                                                src={ getAvatarUrl(coupon.cId?.avatar)}
                                                alt=''
                                                className='round-avatar-40'
                                            />
                                            <div className="ms-2">
                                                <Moment format="YYYY-MM-DD">{coupon.createdAt}</Moment>
                                                <div>{coupon.cId?.username}</div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <MDBTooltip 
                                            wrapperProps={{ color: 'link' }}
                                            wrapperClass="p-0"
                                            title={<div className="text-left">
                                                <div style={{color: '#31ff00'}}>I wrote</div>
                                                <div
                                                    className="font-italic" 
                                                    dangerouslySetInnerHTML={{ __html: text2html(coupon.message ? coupon.message : 'No message')}}>
                                                </div>
                                            </div>}
                                        >
                                            <div className="d-flex align-items-center message-column">
                                                {coupon.freeMinutes > 0 && 
                                                    <div className={`fs-14px free-minutes-coupon ${coupon.status}`} style={{textTransform:'none'}}>
                                                        {(()=>{
                                                            switch(coupon.status){
                                                                case 'available':
                                                                    return <>
                                                                    <div className="me-2 d-flex align-items-center">
                                                                        <SVG  src={`/img/icons/envelop_unchecked.svg`} width={30} height={25} className="me-2" />
                                                                        <span className="fw-bold" >{ coupon.availMinutes }</span>
                                                                        <span className="mx-1">out of </span>
                                                                        <span className="fw-bold">{coupon.freeMinutes}</span>
                                                                        <span className="ms-1"> mins available</span>
                                                                    </div>
                                                                    <div className="comment text-left" style={{textTransform: 'none'}}>
                                                                        <span>(Exp date : </span> 
                                                                        <Moment format="YYYY-MM-DD">{coupon.expireAt}</Moment>
                                                                        <span>)</span>
                                                                    </div>
                                                                    </>;
                                                                case 'consumed':
                                                                    return <>
                                                                        <div className="me-2 d-flex align-items-center">
                                                                            <SVG  src={`/img/icons/envelop_unchecked.svg`} width={30} height={25} className="me-2" />
                                                                            <span>{coupon.freeMinutes} free mins</span>
                                                                        </div>
                                                                        <div className="comment text-left" style={{textTransform: 'none'}}>(consumed)</div>
                                                                    </>;
                                                                case 'expired':
                                                                    return <>
                                                                        <div className="me-2 d-flex align-items-center">
                                                                            <SVG  src={`/img/icons/envelop_unchecked.svg`} width={30} height={25} className="me-2" />
                                                                            <span>{coupon.freeMinutes} free mins</span>
                                                                        </div>
                                                                        <div className="comment text-left" style={{textTransform: 'none'}}>(expired)</div>
                                                                    </>;
                                                            }
                                                        })()}
                                                    </div>
                                                }
                                                {coupon.freeMinutes == 0 && 
                                                    <div className="fs-14px no-free-minutes-coupon text-gray1" style={{textTransform:'none'}}>
                                                        <SVG src='/img/icons/envelop_unchecked.svg' width={30} height={25} className="me-2"/>
                                                    </div>
                                                }
                                            </div>
                                        </MDBTooltip>
                                    </td>
                                </tr>
                            ))
                        } else {
                            return <tr><td colSpan={6} className="text-center">
                                {loading ? '' : <div className="d-flex justify-content-center align-items-center">
                                    <SVG src='/img/icons/no_found.svg' style={{height:40, width: 40}} /><div className="ms-2 mt-1">No Messages</div>
                                </div>}
                            </td></tr>
                        }
                    })()}
                </MDBTableBody>
            </MDBTable>
        </section>
        )
    }

    return (<section className="advisor-all-coupons">
            <div className="confideas-table-header">
                <SearchBar>
                    <div className="search-item">
                        <Select className="search-item me-2"
                                options={perPageOpts} 
                                onChange={(opts) => onPerPageChanged(opts)} 
                                defaultValue={curPerPageOpt} />
                    </div>
                    <div className="me-2 search-item" style={{width: 150}}>
                        <Select className=""
                                options={statusOpts} 
                                onChange={(opts) => onStatusChanged(opts)} 
                                defaultValue={curStatusOpt} />
                    </div>
                    <div className='search-item search-term'>
                        <MDBInput type="text" 
                                label="Search Client"
                                value={search}
                                onChange={onSearchClientChanged}
                                onKeyDown={onSearchClientKeyPress}
                        />
                        <span className='mx-2 cursor-pointer' onClick={onSearchBtnClicked}>
                            <MDBIcon fas icon='search' />
                        </span>
                    </div>
                </SearchBar>
            </div>

            <ScrollHorizon className="coupon-list mt-4">
                { renderCouponsPC(coupons) }
                { renderCouponsMobile(coupons) }
                {loading && <LoadingIndicator /> }
            </ScrollHorizon>

            <div className='d-flex justify-content-center mt-4'>
                <Paginator curPage={searchFormData.curPage} total={total} perPage={searchFormData.perPage} onPageChanged={onPageChanged} />
            </div>
        </section>
    )
});

export default AdvisorAllCoupons;