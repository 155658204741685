import React, { useEffect, useState } from "react";
import SVG from 'react-inlinesvg';
import './ChatDetail.scss';
import { MDBBtn, MDBCard, MDBCardBody, MDBContainer } from "mdb-react-ui-kit";
import ChatMetaInfo from "./ChatMetaInfo";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import ApiService from "../../Core/Service/ApiService";
import { useDispatch, useSelector } from "react-redux";
import { memoizedGetAuthUser } from "../../Store/Reducer/authReducer";
import { useNavigate, useParams } from "react-router-dom";
import { getPreviousUrl, popHistory } from "../../Store/Reducer/urlHistoryReducer";
import { v_url } from "../../Utils/utils";
import { UserRole } from "../../Constants/constants";

const ChatDetail = React.memo((props) => {
    const dispatch = useDispatch();
    const previousUrl = useSelector(getPreviousUrl)
    const params = useParams();
    const chatId = params._id;
    const [loading, setLoading] = useState(false);
    const [chat, setChat] = useState(null);
    const authUser = useSelector(memoizedGetAuthUser);
    const navigate = useNavigate();
    
    useEffect(()=>{
        if(authUser) {
            setLoading(true);

            ApiService.get('/chat/' + chatId).then(result => {
                setChat(result.data.chat);
            })
            .finally(()=>{
                setLoading(false);
            });
        }
    }, [authUser]);

    const goBack = (e) => {
        e.preventDefault();
        const url = authUser?.role === UserRole.ADVISOR ? v_url(`/advisor/client/detail/${chat.cId._id}`) : v_url(`/client/advisor/detail/${chat.aId._id}`);
        navigate(url);
    }

    return (
        <MDBContainer breakpoint="lg" className="chat-detail-container d-flex flex-column align-items-center p-2">
            {chat && (<div className="w-100" style={{maxWidth: 700}}>
                <div className='mb-4 w-100'>
                    <MDBBtn 
                        color='second' 
                        className='d-flex align-items-center' 
                        onClick={goBack}
                    >
                        <SVG src='/img/icons/angle-left.svg' width={15} height={15} fill='#555' />
                        <span className='ms-2'>Back</span>
                    </MDBBtn>
                </div>

                <div className="d-none d-md-block w-100">
                    <MDBCard>
                        <MDBCardBody className="p-4">
                            <ChatMetaInfo chat={chat} />
                        </MDBCardBody>
                    </MDBCard>
                </div>

                <div className="d-block d-md-none w-100">
                    <ChatMetaInfo chat={chat} />
                </div>
            </div>)}
            {loading && <LoadingIndicator />}
        </MDBContainer>
    )
});

export default ChatDetail;