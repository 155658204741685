import { MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBIcon, MDBInput } from "mdb-react-ui-kit";
import React from "react"
import { useEffect } from "react";
import { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { memoizedGetAuthUser, updateAuth } from "../../../Store/Reducer/authReducer";
import LoadingIndicator from "../../../Components/LoadingIndicator/LoadingIndicator";
import ApiService from "../../../Core/Service/ApiService";
import { setPaymentRequest } from "../../../Core/Service/JwtService";
import { floatV, v_url } from "../../../Utils/utils";

const MyBalance = React.memo((props) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const authUser = useSelector(memoizedGetAuthUser);
    const navigate = useNavigate();
    const [chargeAmount, setChargeAmount] = useState(0);
    const [balance, setBalance] = useState(authUser?.balance ?? 0);

    useEffect(()=>{
        if(authUser) {
            setLoading(true);

            ApiService.get('/client/auth').then(result=>{
                dispatch(updateAuth({
                    balance: result.data.client.balance
                }))
                setBalance(floatV(result.data.client.balance));
            })
            .catch(error=>{

            })
            .finally(()=>{
                setLoading(false);    
            })
        }
    }, []);

    const onChargeBtnClicked = (e) => {
        e.preventDefault();
        if(chargeAmount < 10) {
            toast.error("Please input charge amount of more than 10");
            return;
        }
        setPaymentRequest({
            purpose: 'charge_wallet'
        });
        navigate(v_url('/client/checkout/stripe/' + chargeAmount));
    }

    return <MDBCard className="balance-card">
        <MDBCardHeader className="d-flex align-items-center">
            <MDBIcon fas icon='money-bill' color="info" size='2x' />
            <span className="px-2"><strong>My Balance</strong></span>
        </MDBCardHeader>
        <MDBCardBody>
            <div className="d-flex balance-and-charge">
                <div className="my-balance">
                    <span>Available Balance: </span>
                    <span className="available-balanace ms-1">$ {balance}</span>
                </div>
                {/* <div className="charge-balance flex-fluid d-flex justify-content-end">
                    <div className="d-flex">
                        <div>
                            <MDBInput type="number" 
                                    className="text-center"
                                    value={chargeAmount} 
                                    min={10}
                                    onChange={(e)=>setChargeAmount(e.target.value)} 
                            /> 
                        </div>
                        <div className="ms-1 me-2 d-flex justify-content-center align-items-center">($)</div>
                        <div>
                            <MDBBtn onClick={onChargeBtnClicked}>Charge</MDBBtn>
                        </div>
                    </div>
                </div> */}
            </div>

            {loading && <LoadingIndicator />}
        </MDBCardBody>
    </MDBCard>
});

export default MyBalance;