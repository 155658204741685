import { MDBBtn, MDBCard,MDBCardBody, MDBCardHeader, MDBCardTitle, MDBCol, MDBIcon, MDBInput, MDBRow, MDBSpinner } from "mdb-react-ui-kit";
import React, {useContext, useState} from "react";
import { useSelector } from "react-redux";
import Switch from "react-switch";
import { getAdvisorServiceStatus } from "../../../Store/Reducer/authReducer";
import { useEffect } from "react";
import { IsChatSocketConnected, getChatSocket } from "../../../Store/Reducer/socketReducer";
import { SendMessages } from "../../../Constants/constants";
import { Link, useNavigate } from "react-router-dom";
import ModalContext from "../../../Context/ModalContext";
import DeviceContext from "../../../Context/DeviceContext";
import { useRef } from "react";
import { v_url } from "../../../Utils/utils";

function Pilot(props) {
    const navigate = useNavigate();
    const serviceStatus = useSelector(getAdvisorServiceStatus);
    const isChatSockConnected = useSelector(IsChatSocketConnected);
    const chatSocket = useSelector(getChatSocket);
    const [switchingSrvStatus, setSwitchingSrvStatus] = useState(false);
    const modalContext = useContext(ModalContext);
    const deviceContext = useContext(DeviceContext);
    const [fixPilot, setFixPilot] = useState(false);
    const fixPilotRef = useRef(false);

    useEffect(()=>{
        const handleScroll = () => {  
            if(window.scrollY > 145 && !fixPilotRef.current) {
                setFixPilot(true);
                fixPilotRef.current = true;
            }
            if(window.scrollY < 145 && fixPilotRef.current) {
                setFixPilot(false);
                fixPilotRef.current = false;
            }
        };
        window.addEventListener('scroll', handleScroll);
            
        return () => {  
            window.removeEventListener('scroll', handleScroll);  
        }; 
    }, []);

    useEffect(()=>{
        setSwitchingSrvStatus(false);
    }, [serviceStatus.isAvailableChat]);

    const onChatAvailableChanged = (checked) => {
        if(isChatSockConnected) {
            chatSocket.emit(SendMessages.ADVISOR_SRV_STATUS_CHG, {
                isAvailableChat: checked
            });
            setSwitchingSrvStatus(true);
        }
    }

    const renderPC = () => {
        return (
            <div className="d-none d-sm-flex chat">
                <div className="me-4">
                    I am available:
                </div>
                <div className="flex-fluid d-flex">
                    <Switch 
                        onChange={onChatAvailableChanged} 
                        checked={serviceStatus.isAvailableChat ?? false}
                        width={200}
                        uncheckedIcon={
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%",
                                    fontSize: 15,
                                    color: "#ccc",
                                    marginLeft: -150
                                }}
                                >
                                {!switchingSrvStatus && <span>Offline</span> } 
                                {switchingSrvStatus && <div><MDBSpinner role='status' size="sm" /></div> }
                            </div>
                        }
                        checkedIcon={
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    height: "100%",
                                    width: '100%',
                                    fontSize: 15,
                                    color: "#FFF",
                                    marginLeft: 55
                                }}
                            >
                                {!switchingSrvStatus && <span>Taking&nbsp;Chat </span>}
                                {switchingSrvStatus && <div><MDBSpinner role='status' size="sm" /></div> }
                            </div>
                        }
                    />
                </div>
                <div className="d-flex">
                    <MDBBtn 
                        color="info"
                        outline
                        onClick={openRateModal} 
                        style={{width:130, textTransform: 'none'}}
                    >
                        <span>Price</span>
                    </MDBBtn>
                    <MDBBtn 
                        color="info" 
                        outline
                        className="ms-3" 
                        onClick={e=>navigate(v_url('/advisor/edit/service'))}
                        style={{width:130, textTransform: 'none'}}
                    >
                        My Listing
                    </MDBBtn>
                </div>
            </div>
        )   
    }
    const renderMobile = () => {
        return (
        <div className="d-block d-sm-none">
            <div className="d-flex chat">
                <div className="d-flex align-items-center me-2">
                    I am available
                </div>
                <div className="flex-fluid d-flex justify-content-center">
                    <Switch 
                        onChange={onChatAvailableChanged} 
                        checked={serviceStatus.isAvailableChat ?? false}
                        width={150}
                        uncheckedIcon={
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%",
                                    fontSize: 15,
                                    color: "#ccc",
                                    marginLeft: -100
                                }}
                                >
                                {!switchingSrvStatus && <span>Offline</span> } 
                                {switchingSrvStatus && <div><MDBSpinner role='status' size="sm" /></div> }
                            </div>
                        }
                        checkedIcon={
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    height: "100%",
                                    width: '100%',
                                    fontSize: 15,
                                    color: "#FFF",
                                    marginLeft: 30
                                }}
                            >
                                {!switchingSrvStatus && <span>Taking&nbsp;Chat </span>}
                                {switchingSrvStatus && <div><MDBSpinner role='status' size="sm" /></div> }
                            </div>
                        }
                    />
                </div>
            </div>
            <div className="d-flex justify-content-between align-items-center mt-4">
                <MDBBtn 
                    outline
                    color="info"
                    onClick={openRateModal} 
                    style={{width:120, textTransform: 'none', paddingTop:6, paddingBottom: 6}}
                >
                    <span>Price</span>
                </MDBBtn>
                <MDBBtn 
                    outline
                    color="info" 
                    onClick={e=>navigate(v_url('/advisor/edit/service'))}
                    style={{textTransform: 'none', paddingTop:6, paddingBottom: 6}}
                >
                    <span>My Listing</span>
                </MDBBtn>
            </div>
        </div>
        )
    }
    const renderFixedBar = () => {
        return <div 
            className="fixed-pilot d-flex justify-content-center align-items-center"
            style={{transform: `translateY(${fixPilot ? (window.visualViewport.width > 772 ? 80 : 60) : 0}px)`}}
        >
            <span className="me-4">I am available</span>
            <Switch 
                onChange={onChatAvailableChanged} 
                checked={serviceStatus.isAvailableChat ?? false}
                width={150}
                uncheckedIcon={
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            fontSize: 15,
                            color: "#ccc",
                            marginLeft: -100
                        }}
                        >
                        {!switchingSrvStatus && <span>Offline</span> } 
                        {switchingSrvStatus && <div><MDBSpinner role='status' size="sm" /></div> }
                    </div>
                }
                checkedIcon={
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            height: "100%",
                            width: '100%',
                            fontSize: 15,
                            color: "#FFF",
                            marginLeft: 30
                        }}
                    >
                        {!switchingSrvStatus && <span>Taking&nbsp;Chat </span>}
                        {switchingSrvStatus && <div><MDBSpinner role='status' size="sm" /></div> }
                    </div>
                }
            />
        </div>
    }
    const openRateModal = () => {
        modalContext.setOpenAdvisorRateModal(true);
    }

    return (
        <MDBCard className="my-pilot mb-4">
            <MDBCardHeader className="d-flex align-items-center">
                <div className="d-flex align-items-center">
                    <MDBIcon fas icon='info-circle' color="info" size='2x' />
                    <span className="px-2"><strong>Pilot</strong></span>
                </div>
            </MDBCardHeader>
            <MDBCardBody>
                {renderPC()}
                {renderMobile()}
                {renderFixedBar()}
            </MDBCardBody>
        </MDBCard>
    )
}

export default Pilot;