import React, {useContext, useEffect, useRef, useState} from 'react';
import { Outlet } from "react-router";
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { IsAuthenticated, getAuthToken, memoizedGetAuthUser, setAdvisorServiceStatus, setAuth, updateAuth, updateAuthToken } from '../Store/Reducer/authReducer';
import { IsChatSocketConnected, IsNfSocketConnected, getChatSocket, getNfSocket } from '../Store/Reducer/socketReducer';
import { createChat, getChat, removeChat, IsChatCreated, updateChat, memoizedGetChat } from '../Store/Reducer/chatReducer';
import { toast } from 'react-toastify';
import { AdvisorStatusChangeEvent, ChatStatus, RecvMessages, SendMessages, UserRole, UserStatus } from '../Constants/constants';
import { useNavigate, useLocation } from 'react-router-dom';
import {closeChatSocket, createChatSocket, createNfSocket} from '../Core/Service/SocketService';
import ModalContext from '../Context/ModalContext';
import { showWebNotification, updateFavIcon, v_url } from '../Utils/utils';
import { getChangedAdvisors, getChangedAdvisorsHash256, setChangedAdvisors } from '../Store/Reducer/changedAdvisorReducer';
import ApiService from '../Core/Service/ApiService';
import { addAlarms } from '../Store/Reducer/alarmReducer';
import { hookTimer, resetTimer, unHookTimer } from '../Utils/timer';
import { addChatOffMessage, closeChatOffThread } from '../Store/Reducer/chatoffMsgReducer';
import JwtService from '../Core/Service/JwtService';
import { addHistory, getPreviousUrl } from '../Store/Reducer/urlHistoryReducer';


const ServiceCreator = (props) => {
    const dispatch = useDispatch()
    const location = useLocation();
    const previousUrl = useSelector(getPreviousUrl)
    const previousLocation = useRef(null)

    const sockMsgHandlerKey = 'service_creator';
    const navigate = useNavigate();
    const isAuthenticated = useSelector(IsAuthenticated);
    const isChatSockConnected = useSelector(IsChatSocketConnected);
    const isNfSockConnected = useSelector(IsNfSocketConnected);
    const nfSocket = useSelector(getNfSocket);
    const chatSocket = useSelector(getChatSocket);
    const authUser = useSelector(memoizedGetAuthUser);
    const authUserRef = useRef(null);
    const modalContext = useContext(ModalContext);
    const isChatCreated = useSelector(IsChatCreated);
    const chat = useSelector(memoizedGetChat);
    const chatRef = useRef(null);
    const changedAdvisorHash = useSelector(getChangedAdvisorsHash256);
    const changedAdvisors = useSelector(getChangedAdvisors);

    ApiService.setNavigate(navigate);

    useEffect(()=>{
        if(previousLocation.current && previousLocation.current != location.pathname) {
            dispatch(addHistory({url: previousLocation.current}))
        }
        previousLocation.current = location.pathname
    }, [location])


    useEffect(()=>{
        chatRef.current = chat;
    }, [chat]);

    useEffect(()=>{
        authUserRef.current = authUser;
        if(authUser?.user_status === UserStatus.EMAIL_VERIFIED) {
            navigate(v_url('/advisor_auth/signup-step'));
        }
    }, [authUser]);

    useEffect(()=>{
        if(!isAuthenticated) {
            // The following timer is for checking whether there already exists logged-on account in the same browser.
            hookTimer('check_authToken', 1000, () => {
                const authToken = JwtService.getAuthToken();
                const authUser = JwtService.getAuthUser();
                if(authToken && authUser) {
                    dispatch(setAuth({authUser, authToken}));
                }
            })
        } else {
            unHookTimer('check_authToken');
        }
    }, [isAuthenticated]);

    useEffect(()=>{
        if(!isNfSockConnected) {
            createNfSocket(dispatch);
        } else {
            nfSocket.on(RecvMessages.STATUS_CHANGED_ADVISORS, sockMsgHandlerKey, ({advisors}) => {
                console.log('advisor status changed', advisors);
                dispatch(setChangedAdvisors(advisors));
            })
            return () => {
                nfSocket.off(RecvMessages.STATUS_CHANGED_ADVISORS, sockMsgHandlerKey);
            }
        }
    }, [isNfSockConnected]);

    useEffect(()=>{
        if(isAuthenticated) {
            if(changedAdvisors?.length > 0) {
                changedAdvisors.forEach(a => {
                    const faIndex = authUserRef.current.favorite_advisors?.findIndex(fa_id => fa_id=== a._id);
                    if(faIndex >= 0) {
                        // if(a.changeEvent === AdvisorStatusChangeEvent.CHAT_SERVICE_AVAILABLE) {
                        //     toast.success(`@${a.username} is available on service now.`)
                        // }
                        // if(a.changeEvent === AdvisorStatusChangeEvent.ACTIVE) {
                        //     toast.success(`@${a.username} is active now.`)
                        // }
                    }
                })
            }
        } else {
            setTimeout(()=>{
                modalContext.hideAllModals();
            }, 1000);
        }
    }, [changedAdvisorHash, isAuthenticated])

    useEffect(()=>{
        if(isAuthenticated) {
            const currentPath = window.location.pathname;
            if( isChatCreated &&
                ((authUserRef.current.role === UserRole.ADVISOR && authUserRef.current._id === chat.advisor?._id) || (authUserRef.current.role === UserRole.CLIENT && authUserRef.current._id === chat.client?._id)) &&
                chat.status >= ChatStatus.CONNECTED &&
                currentPath != v_url('/chat') )
            {
                console.log('navigating chat room.');
                setTimeout(() => {
                    navigate(v_url('/chat'));
                }, 500);
            }

            if( !isChatCreated && currentPath == v_url('/chat') ) {
                let url = "";
                switch(authUserRef.current.role) {
                    case UserRole.CLIENT:
                        url = v_url('/all_advisors');
                        break;
                    case UserRole.ADMIN:
                        url = v_url('/admin/dashboard');
                        break;
                    case UserRole.ADVISOR:
                        url = v_url('/advisor/dashboard');
                        break;
                }
                setTimeout(() => {
                    navigate(url);
                }, 500);
            }
        }

        if(!isAuthenticated || !isChatCreated) {
            document.title = 'Confideas';
            updateFavIcon('/favicon.ico');
        }
    }, [isAuthenticated, chat]);

    useEffect(()=>{
        if(isAuthenticated) {
            if(isChatSockConnected) {
                if(!authUserRef.current) {
                    toast.error('Something went wrong. Please refresh homepage.');
                    return;
                } else {
                    if(authUserRef.current.role === 'client') {

                    } else if(authUserRef.current.role === 'advisor') {                // in case of advisor
                        chatSocket.emit(SendMessages.GET_ADVISOR_SRV_STATUS);

                        chatSocket.on(RecvMessages.ADVISOR_SRV_STATUS, sockMsgHandlerKey, (serviceStatus) => {
                            console.log('recv advisorServiceStatus', serviceStatus);
                            dispatch(setAdvisorServiceStatus(serviceStatus));
                        })
                    }

                    chatSocket.emit(SendMessages.GET_CHAT_INFO);
                    if(authUserRef.current?.role == UserRole.CLIENT) {
                        chatSocket.emit(SendMessages.GET_CURRENT_USERINFO);
                    }

                    chatSocket.on(RecvMessages.ERROR, sockMsgHandlerKey, ({message}) => {
                        toast.error(message);
                    })
                    chatSocket.on(RecvMessages.CURRENT_USERINFO, sockMsgHandlerKey, (user_info) => {
                        dispatch(updateAuth(user_info));
                    })
                    chatSocket.on(RecvMessages.CHAT_INFO, sockMsgHandlerKey, (chatInfo) => {
                        console.log('current chat status', chatRef.current);
                        if(!(chatInfo.status == chatRef.current?.status)) dispatch(updateChat(chatInfo));
                    });
                    chatSocket.on(SendMessages.REQUEST_CHAT, sockMsgHandlerKey, (chatInfo) => {
                        console.log(SendMessages.REQUEST_CHAT, chatInfo.partner);
                        dispatch(createChat({
                            client: authUserRef.current.role === UserRole.CLIENT ? authUserRef.current : chatInfo.partner,
                            advisor: authUserRef.current.role === UserRole.ADVISOR ? authUserRef.current : chatInfo.partner,
                            chatId: chatInfo.chatId,
                            freeMinutes: chatInfo.freeMinutes,
                            secondsFromCreation: chatInfo.delayedSec ?? 0,
                            status: ChatStatus.WAITING
                        }));

                        if(authUserRef.current.role === UserRole.ADVISOR) {
                            if(document.hidden) {
                                console.log('document is hidden now.')
                                showWebNotification('Message from Confideas', 'Client is calling you.', (result) => {
                                    if(result === false) {
                                        console.log('trying to open allow notification modal.');
                                        modalContext.setOpenAllowNotifyModal(true);
                                    }
                                })
                            }
                        }
                    })
                    chatSocket.on(SendMessages.CANCEL_CHAT, sockMsgHandlerKey, () => {
                        console.log(SendMessages.CANCEL_CHAT);
                        dispatch(removeChat({
                            reason: 'client cancelled.'
                        }));
                    })
                    chatSocket.on(SendMessages.REJECT_CHAT, sockMsgHandlerKey, ()=>{
                        console.log(RecvMessages.REJECT_CHAT);
                        dispatch(removeChat({
                            reason: 'advisor rejected'
                        }));
                        if(authUserRef.current.role === UserRole.CLIENT) toast.warn("Advisor declined the chat");
                    })
                    chatSocket.on(RecvMessages.CHAT_CONNECTED, sockMsgHandlerKey, () => {
                        if(chatRef.current) {
                            dispatch(updateChat({
                                status: ChatStatus.CONNECTED,
                            }));
                        }
                    })
                    chatSocket.on(RecvMessages.CHAT_FREE_SETUP, sockMsgHandlerKey, (data) => {
                        dispatch(updateChat({
                            status: ChatStatus.FREE_SETUP_SESSION,
                            isChatting: true,
                            sessionTime: data.freeSetupTime,
                            passedSeconds: data.delayedSec
                        }));
                    })
                    chatSocket.on(RecvMessages.CHAT_COUPON_SESSION, sockMsgHandlerKey, (data) => {
                        dispatch(updateChat({
                            status: ChatStatus.COUPON_SESSION,
                            isChatting: true,
                            sessionTime: data.couponTime,
                            passedSeconds: data.delayedSec
                        }));
                    })
                    chatSocket.on(RecvMessages.CHAT_PAY_SESSION, sockMsgHandlerKey, (data) => {
                        dispatch(updateChat({
                            status: ChatStatus.PAY_SESSION,
                            isChatting: true,
                            sessionTime: data.sessionTime,
                            chatTime: data.chatTime,              // total chat time so far
                            passedSeconds: data.delayedSec
                        }));
                    })
                    chatSocket.on(RecvMessages.RECV_ALARM, sockMsgHandlerKey, ({alarms}) => {
                        console.log('recv alarm', alarms);
                        dispatch(addAlarms(alarms));
                    })
                    chatSocket.on(RecvMessages.RECV_CHATOFF_MESSAGE, sockMsgHandlerKey, ({message, advisor, client}) => {
                        console.log('recv chatoff message', message)
                        dispatch(addChatOffMessage({
                            message,
                            aId: advisor,
                            cId: client,
                            is_recv_msg: true
                        }))
                    });
                    chatSocket.on(RecvMessages.CHATOFF_THREAD_CLOSED, sockMsgHandlerKey, ({thread_id}) => {
                        console.log('chatoff_thread_was_closed', thread_id);
                        dispatch(closeChatOffThread(thread_id));
                    });
                    chatSocket.on(RecvMessages.UPDATE_AUTH_TOKEN, sockMsgHandlerKey, ({newToken}) => {
                        console.log('update auth token');
                        dispatch(updateAuthToken(newToken));

                        // if auth_token changed, we need to reconnect chatSocket with the new auth_token.
                        // but, don't try to reconnect soket here.
                        // if chatsocket was disconnected, it will be automatically reconnected in the below useEffect function.
                        // so, here we just disconnect chatSocket.
                        chatSocket.disconnect();
                    })
                    chatSocket.on(RecvMessages.NO_FOUND_CHAT, sockMsgHandlerKey, () => {
                        if(chatRef.current?.chatId) {
                            toast.warning("Chat is closed");
                        }
                        dispatch(removeChat());
                    });
                    chatSocket.on(RecvMessages.UNKNOWN_ERROR, sockMsgHandlerKey, ({message})=>{
                        toast.error(message ?? 'Unknown Error');
                    });
    
                    return () => {
                        chatSocket.off(RecvMessages.ADVISOR_SRV_STATUS, sockMsgHandlerKey);
                        chatSocket.off(RecvMessages.ERROR, sockMsgHandlerKey);
                        chatSocket.off(RecvMessages.REQUEST_CHAT, sockMsgHandlerKey);
                        chatSocket.off(RecvMessages.CANCEL_CHAT, sockMsgHandlerKey);
                        chatSocket.off(SendMessages.REJECT_CHAT, sockMsgHandlerKey);
                        chatSocket.off(RecvMessages.CHAT_CONNECTED, sockMsgHandlerKey);
                        chatSocket.off(RecvMessages.RECV_ALARM, sockMsgHandlerKey);
                        chatSocket.off(RecvMessages.RECV_CHATOFF_MESSAGE, sockMsgHandlerKey);
                        chatSocket.off(RecvMessages.CHATOFF_THREAD_CLOSED, sockMsgHandlerKey);
                        chatSocket.off(RecvMessages.UPDATE_AUTH_TOKEN, sockMsgHandlerKey);
                        chatSocket.off(RecvMessages.NO_FOUND_CHAT, sockMsgHandlerKey);
                        chatSocket.off(RecvMessages.UNKNOWN_ERROR, sockMsgHandlerKey);
                    }
                }
            } else {
                createChatSocket(dispatch);
            }
        } else {
            if(isChatSockConnected) closeChatSocket(dispatch);
        }
    }, [isAuthenticated, isChatSockConnected]);

    return <Outlet />
}

export default ServiceCreator;