import React, { useState, useEffect, useRef } from "react";
import {
    PayPalScriptProvider,
    PayPalCardFieldsProvider,
    PayPalCardFieldsForm,
    usePayPalCardFields,
    PayPalNumberField,
    PayPalCVVField,
    PayPalExpiryField,
  } from "@paypal/react-paypal-js";
import { toast } from "react-toastify";
import { MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBSpinner } from "mdb-react-ui-kit";
import ApiService from "../../Core/Service/ApiService";

const SubmitPayment = ({isPaying, setIsPaying, payWithToken}) => {
    const { cardFieldsForm } = usePayPalCardFields();
    const submitHandler = async () => {
        if (!cardFieldsForm) {
        const childErrorMessage = "Unable to find any child components in the <PayPalCardFieldsProvider />";
            throw new Error(childErrorMessage);
        }

        const formState = await cardFieldsForm.getState();
        if (!formState.isFormValid) {
            return alert("The payment form is invalid");
        }

        setIsPaying(true);
        cardFieldsForm.submit().then((response) => {
            console.log(response);
        })
        .catch((error) => {
            console.error(error);
        })
        .finally(()=>{
            setIsPaying(false);
        });
    }
    return (
        <MDBBtn onClick={submitHandler} color="primary" className="w-100" style={{textTransform: 'none'}} disabled={isPaying}>
            {(()=>{
                if(isPaying && !payWithToken) {
                    return (
                        <div className="d-flex align-items-center justify-content-center">
                            <MDBSpinner size="sm" />
                            <span className="ms-2">Checking out...</span>
                        </div>
                    )
                } else {
                    return (
                        <div className="d-flex align-items-center justify-content-center">
                            <span className="ms-2">Checkout with new card number</span>
                        </div>
                    )
                }
            })()}

            
        </MDBBtn>
    )
};

const CreditCardCheckOut = React.memo((props) => {
    const [message, setMessage] = useState({
        msgType: '',
        message: ''
    });
    const [isPaying, setIsPaying] = useState(false);
    const [payWithToken, setPayWithToken] = useState(false);
    const amount = useRef(0);

    useEffect(()=>{
        amount.current = props.amount
    }, [props.amount]);

    const createOrder = async () => {
        try {
            setMessage({type: 'info', message: 'Creating Payment...'});
            const response  =  await ApiService.post('/client/paypal/create_payment_creditcard', {amount: amount.current});
            if(response.data.id) {
                return response.data.id;
            } else {
                const errorDetail = response.data.details?.[0];
                const errorMessage = errorDetail
                    ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                    : JSON.stringify(orderData);
    
                toast.warning(errorMessage);
            }
        }
        catch(error) {
            console.error(error);
            toast.warning('Could not initiate PayPal Checkout');
        }
    }
    const onApprove = async(data, actions) => {
        try {
            setMessage({type: 'info', message: 'Checking Payment...'});
            const response = await ApiService.post("/client/paypal/capture", {orderId: data.orderID});
            setMessage({type: 'info', message: 'Payment successful'})

            // below, I am using setTimeout because UI effect.
            setTimeout(()=>{
                props.onPaymentSuccess && props.onPaymentSuccess(parseFloat(response.data.balance));
            }, 500);
        } catch(error) {
            console.error(error);
            setMessage({type: 'error', message: 'Payment failed.'})
        }
    }
    const onError = async(error) => {
        setMessage({
            msgType: 'error',
            message: 'Something went wrong. Please check your credit information'
        })
    }
    const checkoutWithLastPayment = async () => {
        setIsPaying(true);
        setPayWithToken(true);
        setMessage({msgType: 'info', message: 'Creating Payment...'});
        ApiService.post('/client/paypal/create_payment_creditcard_token', {amount: amount.current}).then(response => {
            props.onPaymentSuccess && props.onPaymentSuccess(response.data.balance)
        })
        .catch((error) => {
            setMessage({
                msgType: 'error',
                message: error.response.data.message
            })
        })
        .finally(()=>{
            setIsPaying(false);
            setPayWithToken(false);
        });
    }

    const cardStyle = {
        'input': {
            'font-size': '16px',
            'font-family': 'courier, monospace',
            'font-weight': 'lighter',
            'color': '#000',
            'padding': '5px 10px',
        },
        '.invalid': {
            'color': 'purple',
        },
    };

    const renderLastPayment = () => {
        return (
        <MDBCard className="mb-4">
            <MDBCardBody>
                <p className="text-blue1">You had a chat before.</p>
                <MDBBtn 
                    className="confideas-primary-btn w-100" 
                    style={{textTransform: 'none'}}
                    onClick={checkoutWithLastPayment}
                    disabled={isPaying}
                >
                    {(()=>{
                        if(isPaying && payWithToken) {
                            return (
                                <div className="d-flex align-items-center justify-content-center">
                                    <MDBSpinner size="sm"/>
                                    <span className="ms-2">Checking out...</span>
                                </div>
                            )
                        } else {
                            return <div>
                                <span>Checkout with your last payment</span>
                                {props.paymentTokens.credit_token.last_digits && <span className="ms-2">(***{props.paymentTokens.credit_token.last_digits})</span>}
                            </div>;
                        }
                    })()}

                </MDBBtn>
            </MDBCardBody>
        </MDBCard>
        )
    }

    const renderNewPayment = () => {
        return (
        <MDBCard>
            <MDBCardBody>
                <PayPalCardFieldsProvider
                    createOrder={createOrder}
                    onApprove={onApprove}
                    onError={onError}
                    style={cardStyle}
                >
                    {/* <PayPalCardFieldsForm /> */}
                    <PayPalNumberField  />
                    <div className="d-flex">
                        <PayPalExpiryField className="w-50" />
                        <PayPalCVVField className="w-50" />
                    </div>
                    <SubmitPayment isPaying={isPaying} setIsPaying={setIsPaying} payWithToken={payWithToken}/>
                </PayPalCardFieldsProvider>   
            </MDBCardBody>
        </MDBCard>
        )
    }

    return (<>
        {props.paymentTokens?.credit_token?.avail && renderLastPayment()}
        {renderNewPayment()}

        {message && (
            <div className={`d-flex justify-content-center align-items-center mt-2 msg-type ${message.msgType}`}>
                <span className="fs-100 ms-2">{ message.message }</span>
            </div>
        )}
    </>)
});

export default CreditCardCheckOut;