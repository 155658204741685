import { MDBBtn, MDBContainer } from "mdb-react-ui-kit";
import SVG from 'react-inlinesvg';
import React from "react";
import ClientAdvisorChatHistory from "./AdvisorChatHistory";
import { useNavigate, useParams } from "react-router-dom";
import './AdvisorDetail.scss'
import ClientAdvisorStatistics from "./Statistics";
import { v_url } from "../../../Utils/utils";

const ClientAdvisorDetail = React.memo((props) => {
    const navigate = useNavigate();
    const params = useParams();
    const aId = params.aId;

    return (
        <MDBContainer breakpoint="lg" className="client-advisor-detail p-4">
            <div className="mb-4">
                <MDBBtn color='second' className='d-flex align-items-center' onClick={e=>navigate(v_url('/client/chat/last_by_advisor'))}>
                    <SVG src='/img/icons/angle-left.svg' width={15} height={15} fill='#555' />
                    <span className='ms-2'>Back</span>
                </MDBBtn>
            </div>

            <ClientAdvisorStatistics aId={aId} />
            <ClientAdvisorChatHistory aId={aId} />
        </MDBContainer>
    )
});

export default ClientAdvisorDetail;