import { MDBCard, MDBCardBody, MDBCardHeader, MDBContainer, MDBIcon, MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import React, {useEffect, useState} from "react";
import SVG from 'react-inlinesvg';
import LoadingIndicator from "../../../Components/LoadingIndicator/LoadingIndicator";
import Moment from "react-moment";
import Select from "react-select";
import ApiService from "../../../Core/Service/ApiService";
import Paginator from "../../../Components/Paginator/Paginator";
import { PaymentMethod, PaymentStatus, TransactionType } from "../../../Constants/constants";
import ScrollHorizon from "../../../Components/ScrollHorizon/ScrollHorizon";
import SearchBar from "../../../Components/SearchBar/SearchBar";

const AdvisorTransactions = React.memo(props=>{
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [transactions, setTransactions] = useState([]);
    const [searchFormData, setSearchFormData] = useState({
        perPage: 10,
        pageNum: 1,
        paymentStatus: ''
    })

    const perPageOpts = [
        { value: 10,label: "10" },
        { value: 20, label: "20" },
        { value: 30, label: "30" }
    ];

    const paymentStatusOpts = [
        { value: '', label: 'All' },
        { value: PaymentStatus.PAYOUT_UNCLAIMED, label: 'Unclaimed' },
        { value: PaymentStatus.PAYOUT_SUCCESS, label: 'Success' },
    ];

    const onPerPageChanged = (opt) => {
        setSearchFormData({
            ...searchFormData,
            perPage: opt.value,
            pageNum: 1
        });
    }
    const onPageChanged = (pageNum) => {
        setSearchFormData({
            ...searchFormData,
            pageNum
        });
    }
    const onPaymentStatusChanged = (opt) => {
        setSearchFormData({
            ...searchFormData,
            payment_status: opt.value,
            pageNum: 1
        });
    }

    useEffect(()=>{
        getTransactions();
    }, [searchFormData])

    const getTransactions = () => {
        setLoading(true);
        ApiService.post('/advisor/mytransactions', searchFormData).then(result=>{
            setTransactions(result.data.transactions);
            setTotal(result.data.total);
        })
        .finally(()=>{
            setLoading(false);
        })
    }

    const renderTransactions = () => {
        if(total === 0) {
            return <tr>
                <td colSpan={4} className="text-center">
                    {loading ? '' : <div className="d-flex justify-content-center align-items-center">
                        <SVG src='/img/icons/no_found.svg' style={{height:40, width: 40}} /><div className="ms-2 mt-1">No Payout</div>
                    </div>}
                </td>
            </tr>
        } else {
            return transactions.map((t, index)=>{
                return (
                    <tr key={index}>
                        <td className="time"><Moment format="YYYY-MM-DD">{t.createdAt}</Moment></td>
                        <td>
                            <div>{t.payment_id}</div>
                            <div className={`mt-1 payment-status ${t.status}`}>{t.status}</div>
                        </td>
                        <td>$ { t.amount }</td>
                    </tr>
                )
            })
        }
    }

    return <MDBCard className="transactions-card mt-4">
        <MDBCardHeader className="d-flex align-items-center">
            <MDBIcon fas icon='exchange-alt' color="info" size='2x' />
            <span className="px-2"><strong>Payouts</strong></span>
        </MDBCardHeader>
        <MDBCardBody>
            <div className="confideas-table-header">
                <SearchBar>
                    <div className="search-item">
                        <Select className="me-2"
                                options={perPageOpts} 
                                onChange={(opts) => onPerPageChanged(opts)} 
                                value={perPageOpts.find(opt=>opt.value == searchFormData.pageNum)} />
                    </div>
                    <div className="search-item me-2" style={{width: 150}}>
                        <Select className=""
                                options={paymentStatusOpts} 
                                onChange={(opts) => onPaymentStatusChanged(opts)} 
                                value={paymentStatusOpts.find(opt=>opt.value == searchFormData.paymentStatus)} />
                    </div>
                </SearchBar>
            </div>

            <ScrollHorizon>
                <MDBTable>
                    <MDBTableHead>
                        <tr>
                            <th>Date</th>
                            <th>Transaction ID</th>
                            <th>Amount</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {renderTransactions()}
                    </MDBTableBody>
                </MDBTable>
            </ScrollHorizon>

            <div className='d-flex justify-content-center mt-4'>
                <Paginator total={total} perPage={searchFormData.perPage} onPageChanged={onPageChanged} />
            </div>
        </MDBCardBody>
    </MDBCard>
})

export default AdvisorTransactions;
