import { MDBCard, MDBCardHeader, MDBCardBody, MDBTable, MDBTableBody, 
        MDBTableHead, MDBInput, MDBIcon, MDBBtn } from "mdb-react-ui-kit";
import React, { useContext } from "react";
import SVG from 'react-inlinesvg';
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Moment from "react-moment";
import Paginator from "../../../Components/Paginator/Paginator"
import ApiService from "../../../Core/Service/ApiService";
import LoadingIndicator from "../../../Components/LoadingIndicator/LoadingIndicator";
import { compareDates, floatV, getAvatarUrl, v_url } from "../../../Utils/utils";
import ScrollHorizon from "../../../Components/ScrollHorizon/ScrollHorizon";
import SearchBar from "../../../Components/SearchBar/SearchBar";

const AdvisorClients = React.memo((props) => {
    const {detail} = props;
    const [allMyClients, setAllMyClients] = useState(null);
    const [clients, setClients] = useState(null);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const perPageOpts = [
        { value: 10,label: "10" },
        { value: 20, label: "20" },
        { value: 30, label: "30" }
    ];
    const [searchClient, setSearchClient] = useState('');
    const [searchFormData, setSearchFormData] = useState({
        searchClient: '',
        pageNum: 1,
        perPage: 10
    })

    const getClientsDetail = async () => {
        if(detail.clients?.length > 0) {
            setLoading(true);

            try {
                const cIds = detail.clients.map(cl => cl._id);

                // To get client earnings
                let response = await ApiService.post('/advisor/client_earnings', {cIds});
                let arr = detail.clients.map(cl => {
                    const earning = response.data.earnings.find(e => e._id == cl._id);
                    if(earning) {
                        return {
                            ...cl,
                            earning
                        }
                    } else {
                        return cl;
                    }
                });
    
                arr = arr.sort((c1, c2) => {
                    return compareDates(new Date(c2.earning?.lastChat ?? '1970-01-01'), new Date(c1.earning?.lastChat ?? '1970-01-01'));
                });

                // To get client message counts
                response = await ApiService.post('/chatoff_message/totalcount', {partnerIds: cIds});
                const { messageCounts } = response.data;
                arr = arr.map(a => {
                    const mc = messageCounts.find(mc => mc._id == a._id);
                    return {
                        ...a,
                        chatOffMessageCount: mc?.total ?? 0
                    }
                })

                setAllMyClients(arr);
            }finally {
                setLoading(false);
            }
        }
    }

    useEffect(()=>{
        getClientsDetail();
    }, [detail?.clients]);
    
    useEffect(()=>{
        if(allMyClients) filterClients();
    }, [allMyClients, searchFormData]);


    const onPerPageChanged = (opt) => {
        setSearchFormData({
            ...searchFormData,
            perPage: opt.value,
            pageNum: 1
        });
    }
    const onPageChanged = (pageNum) => {
        setSearchFormData({
            ...searchFormData,
            pageNum
        });
    }
    const onSearchClientChanged = (e) => {
        setSearchClient(e.target.value);
    }
    const onSearchClientKeyPress = (e) => {
        if(e.key == 'Enter') {
            e.preventDefault();
            setSearchFormData({
                ...searchFormData,
                pageNum: 1,
                searchClient
            });
        }
    }
    const onSearchBtnClicked = () => {
        setSearchFormData({
            ...searchFormData,
            pageNum: 1,
            searchClient
        });
    }

    const filterClients = () => {
        let qClients = searchFormData.searchClient ? allMyClients.filter(cl => cl.username.search(searchFormData.searchClient) != -1) : allMyClients;
        if(qClients.length == 0) {
            setTotal(0);
            setClients(null);
        } else {
            const start = (searchFormData.pageNum - 1) * searchFormData.perPage;
            const end = start + searchFormData.perPage;
            setTotal(qClients.length);
            setClients(qClients.slice(start, end));
        }
    }

    const gotoClientDetail = (e, client) => {
        e.preventDefault();
        navigate(v_url('/advisor/client/detail/' + client._id));
    }
    
    const renderClients = (clients) => {
        return (
            <section className="">
                <MDBTable align='middle' hover>
                    <MDBTableBody>
                        {(()=>{
                            if(clients?.length > 0 ) {
                            return (
                                clients.map((c, index) => (
                                    <tr className="cursor-pointer" key={index} onClick={e=>gotoClientDetail(e, c)}>
                                        <td>
                                            <div className="flex-fluid d-flex align-items-center">
                                                <img className="round-avatar-40" src={ getAvatarUrl(c.avatar)}/>
                                                <div className="d-flex flex-column justify-content-center ms-2">
                                                    <div>{c.username}</div>
                                                    <div className="px-2 fs-90" style={{border: '2px solid #d5d500', borderRadius: 5, color:'#cbb026'}}>
                                                        <span>${floatV(c.earning?.totAmt)}</span>
                                                        <span className="ms-1">earned</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex justify-content-end align-items-center">
                                                <MDBIcon fas icon="angle-right" size="lg" />
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            )
                            } else {
                                return (
                                <tr><td colSpan={2} className="text-center">
                                    {loading ? '' : <div className="d-flex justify-content-center align-items-center">
                                        <SVG src='/img/icons/no_found.svg' style={{height:40, width: 40}} /><div className="ms-2 mt-1">No History</div>
                                    </div>}
                                </td></tr>
                                )
                            }
                        })()}
                    </MDBTableBody>
                </MDBTable>
            </section>
        )
    }

    return (<>
        <MDBCard className="my-clients mb-4">
            <MDBCardHeader className="d-flex">
                <div className="d-flex align-items-center">
                    <MDBIcon fas icon='user-friends' color="info" size='2x' />
                    <span className="px-2" style={{color: '#222'}}><strong>Chat History</strong></span>
                </div>
            </MDBCardHeader>

            <MDBCardBody>
                <div className="d-flex align-items-center confideas-table-header">
                    <SearchBar>
                        <div className="search-item me-2">
                            <Select className="per-page"
                                    options={perPageOpts} 
                                    onChange={(opts) => onPerPageChanged(opts)} 
                                    value={perPageOpts.find(opt=>opt.value == searchFormData.perPage)} />
                        </div>
                        <div className='search-item search-term me-2 d-flex'>
                            <MDBInput type="text" 
                                    label="Search"
                                    value={searchClient}
                                    onChange={onSearchClientChanged}
                                    onKeyDown={onSearchClientKeyPress}
                            />
                            <span className='ms-1 d-flex align-items-center cursor-pointer' onClick={onSearchBtnClicked}>
                                <MDBIcon fas icon='search' />
                            </span>
                        </div>
                    </SearchBar>
                </div>
                <ScrollHorizon className="client-list">
                    { renderClients(clients) }
                    {loading && <LoadingIndicator /> }
                </ScrollHorizon>

                <div className='d-flex justify-content-center mt-4'>
                    <Paginator total={total} perPage={searchFormData.perPage} onPageChanged={onPageChanged} />
                </div>
            </MDBCardBody>
        </MDBCard>
    </>)
});

export default AdvisorClients;